import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MainService } from './services/main.service';
import { localStorageService } from './services/localstorage.service';

export const authGuard: CanActivateFn = async (route, state) => {
    const _local = inject(localStorageService);
    const _main = inject(MainService);
    const router = inject(Router);
    if (await isAuthenticated()) {
        return true;
    } else {
        if (_main.path_redirect_to && _main.path_redirect_to.includes('website')) {
            router.navigate(['/register'], { queryParamsHandling: 'merge' });
            return false;
        } else {
            _local.customClearAllItems();
            router.navigate(['/login'], { queryParamsHandling: 'merge' });
            return false;
        }
    }
};

async function isAuthenticated() {
    const localService = new localStorageService();
    if (!localService.customGetItem('token') || localService.customGetItem('token') == null || localService.customGetItem('reset_password_token')) {
        return false;
    } else {
        return true;
    }
}