<div style="background-image: linear-gradient(to right, #183813 0%, #b6e0ad 100%);">
    <div class="container pt-4 pb-4 mb-4">
        <a href="#"><img src="assets\images\icons\FM-logo.svg" /></a>
    </div>
</div>
<div class="container">
    <div class="row">
        <div [innerHTML]="dynamic_html | safeHtml">
        </div>
    </div>
</div>