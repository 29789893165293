<div class="modal-dialog m-0 p-4 document">
    <div class="modal-header p-0 ps-2 justify-content-between align-items-center border-0">
        <h2 class="modal-title fs-20 fw-700" id="exampleModalLabel">
            Logout
        </h2>
       
        <img src="assets/images/icons/cross-icon.svg" alt="" class="hw-32 border p-1 cursor rounded-circle"  (click)="onClose()">
    </div>
    <div class="modal-body fs-16 fw-500 text-cener ps-2 p-0 pb-4">
        Are you sure you want to logout?
    </div>
    <div class="modal-footer -center mt-5 border-0 p-0">
        <button type="button" class="btn btn-secondary btn-pill fw-600 w-100 mb-3" (click)="logout()">Yes, I Confirm</button>
        <button type="submit" class="btn btn-default fw-600 w-100" (click)="onClose()">Cancel</button>
    </div>
</div>
