import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { passwordValidator, SharedService } from 'src/app/services/shared.service';
import { MainService } from 'src/app/services/main.service';
import { localStorageService } from 'src/app/services/localstorage.service';
import { PasswordSpaceValidator, PasswordStrengthService } from 'src/app/services/password-strength.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit {

  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  newPasswordForm: UntypedFormGroup;
  minLength : boolean = false;
  hasUpperCase: boolean = false;
  hasMaxChar: boolean = false;
  hasNumber : boolean = false;
  hasSpecialChar : boolean = false;
  passwordStrength: any;
  totalStrength: number = 0;
  showPassTooltip  = false;

  constructor(private fb: UntypedFormBuilder, private router: Router, private mainService: MainService,
    private _local: localStorageService, private apiSer: ApiService, private _shared: SharedService, private passwordStrengthService: PasswordStrengthService,) {
    this.newPasswordForm = this.fb.group({
      newPassword: ["", [Validators.required, Validators.minLength(6), passwordValidator(), PasswordSpaceValidator.cannotContainSpace]],
      confirmPassword: ["", Validators.required],
    }, { validator: this.mainService.matchPassword });
  }

  ngOnInit(): void {
    if (!this._local.customGetItem('reset_password_token')) {
      this.mainService.accessTokenLogin();
    }
    this.newPasswordForm.get('newPassword')?.valueChanges.subscribe(password => {
      // Contains uppercase letters (A-Z)
    if ((/[A-Z]/.test(password)) && (/[a-z]/.test(password))) {
      this.hasUpperCase = true;
    }else{
      this.hasUpperCase = false;
    }

    // Contains numbers (0-9)
    if (/[0-9]/.test(password)) {
      this.hasNumber = true;
    }else{
      this.hasNumber = false;
    }

    // Contains special characters (!@#$%^&*)
    if (/[!@#$%^&*]/.test(password)) {
      this.hasSpecialChar = true;
    }else{
        this.hasSpecialChar = false;
      }

    if (password.length >= 6 ) {
      this.hasMaxChar = true;
    }else{
      this.hasMaxChar = false;
    }
    this.totalStrength = (Number(this.hasUpperCase) + Number(this.hasNumber) + Number(this.hasSpecialChar) + Number(this.hasMaxChar))
      this.passwordStrength = this.passwordStrengthService.calculatePasswordStrength(password);
    });
  }

  submitForm(): void {
    if (this.newPasswordForm.controls['newPassword'].errors?.['cannotContainSpace'] ){
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return
    }
    if (this.newPasswordForm.value.newPassword != this.newPasswordForm.value.confirmPassword) {
      this._shared.customPopups('Passwords did not match', 2);
      return;
    }

    if (this.newPasswordForm.value.newPassword.length < 6) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[A-Z]/.test(this.newPasswordForm.value.newPassword)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[a-z]/.test(this.newPasswordForm.value.newPassword)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[0-9]/.test(this.newPasswordForm.value.newPassword)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[!@#$%^&*]/.test(this.newPasswordForm.value.newPassword)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }


    if (!this.newPasswordForm.value.newPassword || this.newPasswordForm.value.newPassword.trim().length == 0) {
      this._shared.customPopups('Enter a valid password', 2);
      return;
    }

    let params = {
      reset_password_token: this._local.customGetItem('reset_password_token'),
      doctor_email: this._local.customGetItem('reset_email').replace(/\s/g, '').toLowerCase(),
      doctor_password: this._shared.crypto(this.newPasswordForm.value.newPassword),
      access_token: this._local.customGetItem('token')
    };

    this.apiSer.api(params, "auth/reset_password").subscribe((data: any) => {
      if (data.is_error == 0) {
        this._local.customSetItem('token', data.access_token);
        this._local.customRemoveItem('reset_password_token');
        this._local.customGetItem('reset_email');

        /*setting side menu once user logged in and got side menu data*/
        if (data.menu) {
          this.mainService.checkProfile(data);
        } else {
          this._shared.customPopups(data.original_text, 1);
        }
      } else {
        this._shared.customPopups(data.original_text || 'We got some critical issues while login. Please contact Administrator.', 1);
      }
      return true;
    })
  }

  routeBack() {
    this._local.customRemoveItem('token')
    this.router.navigate(["/login"]);
  }
}
