import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NewPasswordComponent } from './new-password/new-password.component';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';
import { VerifyPhoneNumberComponent } from './verify-phone-number/verify-phone-number.component';
import { BusinessInfoComponent } from './business-info/business-info.component';
import { LeftLayoutComponent } from './left-layout/left-layout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReferralCodeComponent } from './referral-code/referral-code.component';
import { GoogleTranslateComponent } from './google-translate/google-translate.component';
// const maskConfig: Partial<IConfig> = {
//   validation: false,
// };

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    GoogleSignInComponent,
    VerifyPhoneNumberComponent,
    BusinessInfoComponent,
    ForgotPasswordComponent,
    ReferralCodeComponent,
    GoogleTranslateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule
  ]
})
export class PublicModule { }
