export const environment = {
  production: false,
  // baseUrl: 'https://testapi.financemutual.com/business/',
  baseUrl: 'https://testbusiness.financemutual.com/api/business/',
  requestUrl: 'https://testrequest.financemutual.com/',
  // baseUrl: 'http://localhost:3000/business/',
  captcha_site_key: '6Lch2gYqAAAAAEfYKKg6M_YJsYAuWnx-b0E6JVZ1',
  google_client_id: '124019947414-m6vmukd7vfsnuahhbb48h1e0fhfok9pl.apps.googleusercontent.com',
  app_version: 1,
  app_type: 2,
  device_type: 0,
  device_token: 'Website',
  environment: 'TEST',
  url: 'https://testbusiness.financemutual.com/',
  socketBaseUrl: 'https://testbusiness.financemutual.com',
  intercomAppId: 'hnf5ub99'
};