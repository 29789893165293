import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { localStorageService } from './localstorage.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
    headers: any = new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*' });
    constructor(private http: HttpClient, private _local: localStorageService) {
        super();
    }

    override handleError(error: any) {
        console.log('Chunkload ERROR check');
        if ((error + '').includes('ChunkLoadError')) {
            window.location.reload();
        }
        // Here you can provide whatever logging you want
        // tslint:disable:max-line-length
        super.handleError(error);
        const apiUrl = environment.baseUrl;
        console.error('Error name:', error);
        if (error instanceof Error) {
            console.error('Error name:', error.name);
            console.error('Message:', error.message);
        }


        // tslint:disable:triple-equals
        // this.http.post<any>(apiUrl + 'frontend_error', { error: String(error) != '[object Object]' ? String(error) : JSON.stringify(error), url: window.location.href }, { headers: this.headers })
        //     .subscribe(response => {
        //     });
    }
}
