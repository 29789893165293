import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import * as REGEX from '../../../shared/regex';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { localStorageService } from 'src/app/services/localstorage.service';
import { Email } from 'src/app/shared/regex';
import { SocketsService } from 'src/app/services/socket.service';
import { CaptchaService } from 'src/app/services/captcha.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public env = environment;
  public loginForm: UntypedFormGroup;
  public otpForm: UntypedFormGroup;
  showPassword : boolean = false;
  type: any = {
    password: "password",
    OTP: "OTP",
    email: "email"
  };
  countdownInterval: any;
  otpValueGroup = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  otp_id: any;
  method: any = this.type.email;
  emailOrPhone: any;
  is_email: boolean = true;

  countdown: number = 180; // Countdown starts from 30 seconds

  constructor(private _socket: SocketsService, private main: MainService, private fb: UntypedFormBuilder, private _local: localStorageService, private apiSer: ApiService, private _router: Router, public _shared: SharedService,private _captcha: CaptchaService,) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.maxLength(100)]],
      password: ["", [Validators.maxLength(100)]],
      OTP: ["", [Validators.maxLength(6)]]
    })

    this.otpForm = this.fb.group({
      first: ['', [Validators.required, Validators.maxLength(1)]],
      second: ['', [Validators.required, Validators.maxLength(1)]],
      third: ['', [Validators.required, Validators.maxLength(1)]],
      fourth: ['', [Validators.required, Validators.maxLength(1)]],
      fifth: ['', [Validators.required, Validators.maxLength(1)]],
      sixth: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }
  ngOnInit(): void {
    const token = this._local.customGetItem('token');
    if (token) {
      this.main.accessTokenLogin()
    }
  }

  public async login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAsDirty();
      this.loginForm.markAllAsTouched();
      return;
    }
    if (!(this.loginForm.controls['email'].value + '').trim()) {
      this._shared.customPopups('Please enter valid email', 1);
      return;
    }
    this.apiSer._loader.showLoader();
    const captcha = await this._captcha.getCaptchaToken();
    const data = {
      doctor_email: this.loginForm.controls['email'].value,
      doctor_password: this._shared.crypto(this.loginForm.controls['password'].value),
      captcha : captcha
    };
    this.apiSer.api(data, 'auth/email_login').subscribe((response) => {
      if (response.is_error == 0) {
        if (response.reset_password_token) {
          this._local.customSetItem('token', response.access_token);
          this._local.customSetItem('reset_password_token', response.reset_password_token);
          this._local.customSetItem('reset_email', response.doctor_email || response.agent_email);
          this._router.navigate(['new-password']);
          return;
        }
        this.main.checkProfile(response);
      } else {
        this.loginForm.markAsDirty();
        this.loginForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
    // this.local.customSetItem('token', 'test');
    //   // this.apiSer.api()
    // this.main.auth.accessTokenLogin();
  }

  onInput(event: any, currentIndex: number): void {
    const inputValue = event.target.value;

    if (!(inputValue >= 0) || !(inputValue < 10) ) {
      this.otpForm.get(this.otpValueGroup[currentIndex])?.setValue('');
      return;      
    }
    if (inputValue  && (currentIndex < (this.otpValueGroup.length - 1))) {
      const nextInput = this.otpValueGroup[currentIndex + 1];
      this.setFocus(nextInput);
    }
  }

  onDelete(event: any, currentIndex: number): void {
    if (!event.target.value && currentIndex > 0) {
      const previousInput = this.otpValueGroup[currentIndex - 1];
      this.setFocus(previousInput);
    }
  }

  setFocus(input: string) {
    if (input) {
      document.getElementById(input)?.focus();
    }
  }

  verifyOtp() {
    if (this.otpForm.invalid) {
      this.otpForm.markAsDirty();
      this.otpForm.markAllAsTouched();
      return;
    }
    let data;
    let value = '';
    this.otpValueGroup.filter(input => value += this.otpForm.value[input]);
    let body = {
      otp: value,
      otp_id: this.otp_id,
      doctor_email: this.loginForm.value.email
    }
    this.apiSer.api(body, 'auth/verify_login_otp ').subscribe({
      next: (response) => {
        if (response.is_error == 0) {
          if (response.reset_password_token) {
            this._local.customSetItem('token', response.access_token);
            this._local.customSetItem('reset_password_token', response.reset_password_token);
            this._local.customSetItem('reset_email', response.doctor_email || response.agent_email);
            this._router.navigate(['new-password']);
            return;
          }
          this.main.checkProfile(response);
        } else {
          this.loginForm.markAsDirty();
          this.loginForm.markAsTouched();
          window.scroll(0, 0);
        }
      },
      error: (error) => {
        console.log("dgijhshchbs", error)
      }
    })

  }

  async sendOtp(type: any) {
    this.apiSer._loader.showLoader();
    if (this.otpForm) {
      this.otpForm.reset();
    }
    const captcha = await this._captcha.getCaptchaToken();
    let data = {
      doctor_email: this.loginForm.value.email,
      doctor_mobile: this.loginForm.value.email,
      doctor_mobile_country_code: '+1',
      captcha: captcha
    }

    if (this.is_email) {
      delete data.doctor_mobile
    } else {
      delete data.doctor_email
    }

    this.apiSer.api(data, 'auth/login_otp').subscribe({
      next: (data) => {
        if (!data.is_error) {
          this.otp_id = data.otp_id;
          if (data.otp && !environment.production) {
            this._shared.customPopups(data.otp, 0);
          }
          this.startCountdown();
          this.countdown = 180
          this.method = type;
        }

      },
      error: (error) => { }
    })

  }

  enterPassword_form() {
    const emailOrPhone = this.loginForm.value.email?.trim();
    const emailRegex = REGEX.email_regex;
    const phoneRegex = REGEX.number_regex;

    if (!emailRegex.test(emailOrPhone) && !phoneRegex.test(emailOrPhone)) {
      this._shared.customPopups('Please enter a valid email/phone.', 1);
      return false;
    }

    if (phoneRegex.test(emailOrPhone)) {
      const phoneLength = emailOrPhone.length;
      if (phoneLength < 6 || phoneLength > 12) {
        this._shared.customPopups('Phone number is not valid.', 1);
        return false;
      }
    }

    this.is_email = phoneRegex.test(emailOrPhone) ? false : true;
    return true;

  }


  logInType(type: any) {
    if (!(this.loginForm.controls['email'].value + '').trim()) {
      this._shared.customPopups('Please enter valid email/phone', 1);
      return;
    }

    if (this.loginForm.controls['email'].invalid) {
      this.loginForm.markAsDirty();
      this.loginForm.markAllAsTouched();
      return;
    }

    if (this.enterPassword_form() && type !== "password") {
      this.sendOtp(type);
    }

  }

  startCountdown(): void {
    // Initialize countdown interval

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval); // Stop countdown when it reaches 0
      }
    }, 1000); // 1000ms = 1 second
  }

  renewOtp(): void {
    this.sendOtp("OTP");
    // Generate a new OTP and reset countdown
    this.countdown = 180; // Reset countdown to 180 seconds
    this.startCountdown();
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  resetForm() {
    // this.loginForm.reset(); 
    this.otpForm.reset();
    this.method = "email"
  }

  get f() {
    return this.otpForm.controls;
  }

  handleEnterKey() {
    this.method ='password'
  }

}
