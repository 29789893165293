import { NgModule } from '@angular/core';
import { PublicModule } from './public/public.module';
import { LayoutModule } from '../layouts/layout.module';

@NgModule({
  declarations: [],
  imports: [
    LayoutModule,
    PublicModule
  ]
})
export class PagesModule { }
