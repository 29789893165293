<div class="login-main-wrapper">
    <div class="inner-wrapper d-flex flex-column flex-lg-row overflow-hidden">
        <div class="">
            <app-left-layout></app-left-layout>
        </div>
        <div
            class="register-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto">
            <div class="right-content p-3 p-lg-0">
                <app-google-translate></app-google-translate>
                <div class="mb-4" *ngIf="selectedStep === step.REGISTER">
                    <h2 class="fs-36 fw-600 m-0 mb-3">
                        Let's Make Things <br> Official

                    </h2>
                    <p class="fs-16 fw-400 text-secondary m-0">
                        Please enter your details to begin your journey
                    </p>
                </div>

                <div class="" *ngIf="selectedStep == step.REGISTER">
                    <form class="h-100" (submit)="submitForm()">
                        <div class=" pb-0 h-100 flex-wrap register-business-div">

                            <ng-container [formGroup]="signUpForm">
                                <div class=""
                                    [ngClass]="[(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && signUpForm['controls']['doctor_name'].touched && signUpForm['controls']['doctor_name'].invalid  ? ' inputContainer custom-form ' : 'custom-form']">
                                    <label for="text" class="label-name w-100 fs-14"><span
                                            class="content-name f-c-p-3 fs-14">
                                            Name* </span>
                                    </label>
                                    <input type="text" class="input_field w-100" name="doctor_name" autocomplete="off"
                                        required name="doctor_name" trim="true" maxlength="30"
                                        formControlName="doctor_name" placeholder="Enter Your Name" />
                                </div>
                                <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                    *ngIf="(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && (signUpForm['controls']['doctor_name'].touched  && signUpForm['controls']['doctor_name'].invalid && !signUpForm['controls']['doctor_name'].dirty)">
                                    Please enter name.
                                </p>
                                <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                    *ngIf="(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && (signUpForm['controls']['doctor_name'].touched  && signUpForm['controls']['doctor_name'].invalid  && signUpForm['controls']['doctor_name'].dirty)">
                                    Please enter valid name.
                                </p>

                                <div class=" form-group pr-md-0 mt-3">
                                    <div class=""
                                        [ngClass]="[ signUpForm && (signUpForm['controls']['doctor_email'].touched && signUpForm['controls']['doctor_email'].invalid || (signUpForm['controls']['doctor_email'] && signUpForm['controls']['doctor_email']['errors'])) ? 'custom-form inputContainer' : 'custom-form ']">
                                        <label for="text" class="label-name fs-14 w-100">
                                            Email*
                                        </label>
                                        <input type="text" class="input_field w-100" required name="doctor_email"
                                            maxlength="50" trim="true" formControlName="doctor_email" autocomplete="off"
                                            placeholder="Enter Your Email" />
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf=" ((signUpForm['controls']['doctor_email'].touched || signUpForm['controls']['doctor_email'].dirty) && signUpForm['controls']['doctor_email'].invalid)">
                                        Please enter valid email address.
                                    </p>
                                </div>

                                <div class=" form-group pr-md-0 mt-3">
                                    <div class=" position-relative"
                                        [ngClass]="[signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid  ? '  b-10 m-h-56 custom-form inputContainer' : ' b-10 m-h-56 custom-form']">
                                        <label for="text" class="label-name fs-14 w-100">
                                            Password*
                                        </label>
                                        <div class="position-relative">
                                            <input [type]="!hidePwd ? 'password' : 'text'" class="input_field w-100"
                                                name="password" formControlName="doctor_password" autocomplete="off"
                                                required placeholder="Enter Your Password" />
                                            <img [src]="hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                                alt="" class="position-absolute eye-icon cursor"
                                                (click)="hidePwd = !hidePwd">
                                        </div>
                                    </div>

                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf="signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid && !signUpForm['controls']['doctor_password'].dirty">
                                        Please enter password.
                                    </p>
                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf="signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid && signUpForm['controls']['doctor_password'].dirty">
                                        Please enter valid password (should be greater than 6 characters).
                                    </p>
                                </div>
                                <!-- <p class="reffer-code my-2 fs-14 fw-500 cursor blue-theme-color" *ngIf="!referral_code"
                                    (click)="showReferralModal()">
                                    I have partner referral code
                                </p> -->
                                <div class="d-flex align-items-center justify-content-between" *ngIf="referral_code">
                                    <p
                                        class="reffer-code green my-2 fs-14 fw-500 cursor d-flex align-items-center justify-content-between">
                                        <img src="assets/images/icons/reffer-icon.svg" alt="" class="me-2"> Referral
                                        Code {{referral_code}} added
                                    </p>
                                    <img src="assets/images/icons/red-delete-icon.svg" alt="" *ngIf="referralCodeExist"
                                        class="cursor" (click)="RemoveReferralCode()">
                                </div>

                                <div class=" py-2">
                                    <label class="label-name pointer mb-0 fs-500" style="color: #475467;">
                                        <input type="checkbox" class="pointer primary-checkbox" formControlName="TnC"
                                            id="terms">
                                        <label for="terms" class="mr-1 mb-0 fs-14"> <span>By creating account means that
                                                you agree to the </span></label>
                                        <a target="_blank" class="fw-500 blue-theme-color" tappable style="text-decoration: none"
                                            title="Terms & Conditions" routerLink="/terms/business-owner-terms"
                                            class="fs-14">terms and condition</a>
                                        and our
                                        <a target="_blank" class="fw-500 blue-theme-color" tappable style="text-decoration: none"
                                            title="Terms & Conditions" routerLink="/terms/business-owner-terms"
                                            class="fs-14">privacy policy.</a>
                                    </label>

                                    <p class="mat-error mb-0 ml-0 pl-0 text-danger fs-14"
                                        *ngIf="signUpForm['controls']['TnC'].touched && !signUpForm['controls']['TnC'].value">
                                        Please accept terms & conditions.</p>
                                </div>
                            </ng-container>
                            <div class=" form-group mt-3">
                                <button button-primary-auth type="submit"
                                    class="btn btn-secondary w-100 light fw-600 mb-3">Create Account</button>
                                <!-- (click)="socialSignIn()" -->
                                <app-google-sign-in [country_id]="counCurData.country_id"
                                    [currency_id]="counCurData.currency_id"></app-google-sign-in>
                                <p class="mat-error mb-0 ml-0 pl-0" *ngIf="(signUpForm['errors'])">
                                    {{signUpForm && signUpForm['errors']}}
                                </p>
                            </div>
                        </div>

                        <p class="fs-14 fw-400 m-0 text-center">
                            Already have an account?
                            <span class="fw-500 link-text cursor blue-theme-color" [routerLink]="'/login'">Log In</span>
                        </p>

                    </form>

                </div>

                <ng-container *ngIf="selectedStep === step.VERIFY_OTP">
                    <div class="mb-4">
                        <h2 class="fs-36 fw-600 m-0 mb-3">
                            Verify your Email
                        </h2>
                        <p class="fs-16 fw-400 text-secondary m-0">
                            A one-time OTP has been sent to your email <b>{{signUpForm.controls['doctor_email'].value}}</b> <a (click)="selectedStep = step.REGISTER" class="text-line cursor ms-2">Change</a>.
                        </p>
                    </div>

                    <div class=" form-group mt-4 mb-3">
                        <form [formGroup]="otpForm" action="" (submit)="verifyOtp()">
                            <div class="form-group otp_fields new-otp_fields mb-3 mt-2 d-flex justify-content-between">
                                <input id={{item}} (input)="onInput($event , index)"
                                (keydown.space)="$event.preventDefault()"
                                (keydown.tab)="$event.preventDefault()"
                                    (keydown.backspace)="onDelete($event , index)" [formControlName]="item" type="text"
                                    maxlength="1" *ngFor="let item of otpValueGroup; let index = index" />
                            </div>
                            <button class="btn btn-secondary w-100 fw-600" type="submit">Verify</button>
                        </form>
                    </div>
                    <!-- <a (click)="selectedStep = step.REGISTER" class="text-line cursor">Change your email</a> -->
                </ng-container>
            </div>
        </div>
    </div>
</div>