export const environment = {
  production: false,
  baseUrl: 'https://testbusiness.financemutual.com/api/business/',
  requestUrl: 'https://testrequest.financemutual.com/',
  // baseUrl: 'http://localhost:3000/business/',
  captcha_site_key: '6LeBb8QqAAAAABu3CVJV6cl5Y8dj0LoHMP7LJlKU',
  google_client_id: '124019947414-m6vmukd7vfsnuahhbb48h1e0fhfok9pl.apps.googleusercontent.com',
  app_version: 1,
  app_type: 2,
  device_type: 0,
  device_token: 'Website',
  environment: 'TEST',
  url: 'https://dev.financemutual.com/',
  socketBaseUrl: 'https://testbusiness.financemutual.com',
  intercomAppId: 'mse6jhbe',
  clarity_id: 'q52nbx0b2v'
};