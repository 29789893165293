import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, TimeoutError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, timeout, retry } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoaderService } from '../shared/loader/loader.service';
import { localStorageService } from './localstorage.service';
import { SocketsService } from './socket.service';
import { ToasterService } from '../shared/toaster/toaster.service';

@Injectable()
export class ApiService {
  headers: any = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: '*/*',
  });
  timeoutError: boolean = false;
  deviceInfo = {
    device_info: this.device_info(),
  };

  constructor(
    public http: HttpClient,
    private _local: localStorageService,
    private _shared: SharedService,
    private router: Router,
    private _loader: LoaderService,
    private _socket: SocketsService,
    private _toastr: ToasterService
  ) { }

  api(_data: any, apiName: any, method: string = 'post', showLoader = true): Observable<any> {
    this._local.customSetItem('timezone', this._shared.getCurrentTimezone());
    let apiData: any = {};
    Object.keys(_data).filter((key) => {
      if (_data[key] || Number(_data[key]) == 0) {
        apiData[key] = _data[key];
      }
    });
    if (method == 'post') {
      apiData = { ...apiData, ...this.deviceInfo };
    }
    let hitting_url = environment.baseUrl + apiName;
    let output;
    if (method === 'get') {
      output = this.http.get<any>(hitting_url, {
        headers: this.headers,
        params: apiData,
      });
    } else if (method === 'put') {
      output = this.http.put<any>(hitting_url, apiData, {
        headers: this.headers,
      });
    } else if (method === 'delete') {
      output = this.http.delete<any>(hitting_url + '/' + _data, {
        headers: this.headers,
      });
    } else {
      output = this.http.post<any>(hitting_url, apiData, {
        headers: this.headers,
      });
    }
    if (showLoader) {
      this._loader.showLoader();
    }
    this._toastr.hideAllToasters();
    return output.pipe(
      timeout(60000),
      retry(3),
      map((data: any) => {
        this._loader.hideLoader();
        if (data && data.original_text) {
          this._shared.customPopups(data.original_text || data.err, 1);
        }
        if (data.flag === 500 || data.flag === 401) {          
          // this._local.customClearAllItems();
          this.loginRedirect();
        }
        return data;
      }),
      catchError((error: any) => {
        this._loader.hideLoader();
        if (error instanceof TimeoutError) {
          if (!this.timeoutError) {
            this.timeoutError = true;
            // this.customPopups('Request Timeout', 1);
          }
          return throwError(() => {
            error: 'Timeout Exception';
          });
        }
        return throwError(() => error);
      })
    );
  }

  loginRedirect() {
    let userData = this._local.customGetItem('userProfileData');
    if (typeof userData == 'string') {
      userData = JSON.parse(userData);
    }
    if (userData && userData.doctor_id) {
      this._socket.emit(this._shared.socketIds.DOCTOR_LOGOUT, {doctor_id: userData.doctor_id});
    }
    this._local.customClearAllItems();
    // this.intercom.shutdown();
    this.router.navigate(['login']);
  }

  device_info() {
    var window: any = window;
    var document: any = document;
    // Opera 8.0+
    const isOpera =
      navigator.userAgent.indexOf(' OPR/') >= 0 ||
      navigator.userAgent.indexOf('Opera') >= 0;
    // Firefox 1.0+
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // Edge 20+
    const isEdge = navigator.userAgent.indexOf('Edge') > -1;
    // Internet Explorer 6-11
    const isIE =
      typeof document !== 'undefined' && !!document.documentMode && !isEdge;
    // Chrome 1 - 71
    const isChrome = !isOpera && !isEdge && !isSafari;
    // Blink engine detection
    const isBlink = (isChrome || isOpera) && window && !!window.CSS;
    const browserInfo = {
      isFirefox,
      isChrome,
      isSafari,
      isOpera,
      isIE,
      isEdge,
      isBlink,
    };
    const platform =
      navigator && navigator.platform ? navigator.platform : 'unknown';
    const userAgent =
      navigator && navigator.userAgent ? navigator.userAgent : 'unknown';
    return {
      browser: browserInfo,
      platform,
      userAgent,
    };
  }

  downloadApi(data: any, url: any, view: any) {
    let config = {
      // headers: this.headers,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      params: data
    };
    let api_url = environment.baseUrl;
    this._loader.showLoader();
    return this.http.get<any>(`${api_url}${url}`, config).pipe(
      timeout(60000),
      map((data: any) => {
        this._loader.hideLoader();
        if (!data.is_error) {
          let headers = data.headers.get('Content-Disposition');
          if (!headers) {
            throw new Error('Something went wrong, please try again later');
          }
          let fileName = this.extractFirstText(headers);
          data = data.body;
          let blob = new Blob([data], { type: 'application/pdf' });
          let urlwindow = window.URL.createObjectURL(blob);
          if (view == 0) {
            let anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = urlwindow;
            anchor.click();
          } else if (view == 1) {
            let pwa = window.open(urlwindow);
            if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
              alert("Please disable your browser's pop-up blocker and try again.");
            }
          }
          else if (view == 2) {
            let w: any = window.open(urlwindow, "", "width=800,height=800");
            if (!w || w.closed || typeof w.closed == 'undefined') {
              alert("Please disable your browser's pop-up blocker and try again.");
            }
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              w.location.reload();
            }
            w.focus();
            w.print();
          }
        } else {
          this._shared.customPopups(data.original_text, 1);
          return;
        }
      }),
      catchError((error: any) => {
        this._loader.hideLoader();
        if (error instanceof TimeoutError) {
          if (!this.timeoutError) {
            this.timeoutError = true;
            this._shared.customPopups('Request Timeout', 1);
          }
          throw new Error('Request Timeout');
        }
        if (error.status == 0) {
          if (!this.timeoutError) {
            this._shared.customPopups('Unable to fetch data from server', 1);
          }
        }
        this._shared.customPopups(error, 1);
        throw new Error(error);
      })
    );
  }

  uploadApi(_data: any, apiName: any): Observable<any> {
    let api_url = environment.baseUrl + apiName;
    if (!api_url) {
      api_url = environment.baseUrl;
    }
    this._loader.showLoader();
    return this.http.post<any>(`${api_url}`, _data, {
      headers: this.headers,
    })
      .pipe(
        timeout(60000),
        map((data: any) => {
          this._loader.hideLoader();
          if (data.flag === 500 || data.flag === 401) {
            this._shared.customPopups(data.original_text, 1);
            this._local.customClearAllItems();
            this.loginRedirect();
          }
          if (data && data.original_text) {
            this._shared.customPopups(data.original_text || data.err, 1);
          }
          return data;
        }),
        catchError((error: any) => {
          this._loader.hideLoader();
          if (error instanceof TimeoutError) {
            if (!this.timeoutError) {
              this.timeoutError = true;
              this._shared.customPopups('Request Timeout', 1);
            }
            throw new Error('Timeout Exception');
          }
          if (error.status === 0) {
            if (!this.timeoutError) {
              this._shared.customPopups('Unable to fetch data from server', 1);
            }
          }
          return throwError(error);
        })
      )
  }

  extractFirstText(str: any) {
    const matches = str && str.match(/"(.*?)"/);
    return matches ? matches[1] : str;
  }

  openStripe() {
    this.api({}, 'profile/create_account_link').subscribe((response:any) => {
      if (!response.is_error) {
        console.log(response);
        window.open(response.url, '_self');
      }
    })
  }

  async addOnboarding(id: string): Promise<{ success: boolean, response: any }> {
    return new Promise((resolve, reject) => {
      const obj = {
        stripe_account_id: id
      };
      this.api(obj, 'profile/add_bank_account_onboarding', 'get').subscribe((response: any) => { 
        if (!response.is_error) {
          resolve({ success: true, response });
        } else {
          resolve({ success: false, response });
        }
      })
    });
  }

  async verifyIdentity(): Promise<{ success: boolean, response?: any }> {
    return new Promise((resolve, reject) => {
      this.api({}, 'profile/get_verification_link', 'get').subscribe(resp => {
        if (resp.data.client_secret) {
          this._shared.stripeElement.verifyIdentity(resp.data && resp.data.client_secret).then((data: any) => {
            if (!data.error) {
              this.api({}, 'profile/get_identity_verification_status', 'get').subscribe(response => {
                if (response.verification_status === this._shared.identityStatus.verified) {
                  this._shared.identity_status$.next(this._shared.identityStatus.verified);
                  resolve({ success: true});
                } else {
                  resolve({ success: false});
                }
              })
            }
            // this.mainService.getVerificationState();
          });
        }
      })    
    });
  }
}
