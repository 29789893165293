<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="">
      <app-left-layout></app-left-layout>
    </div>
    <div
      class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto position-relative">
      <!-- <app-google-translate></app-google-translate> -->
      <div class="right-content  p-3 p-lg-0">
        <div class="mb-4">
          <!-- <img src="assets/images/icons/back-arrow-circle.svg" class="cursor mb-4" alt="" *ngIf="method != 'email'"
            (click)="resetForm()"> -->
          <h2 class="fs-36 fw-600 m-0 mb-3" *ngIf="method == type.email">
            Log In to Your <br> Account
          </h2>
          <h2 class="fs-36 fw-600 m-0 mb-3" *ngIf="method != type.email">
            Verification Code Sent
          </h2>
          <p class="fs-16 fw-400 text-secondary m-0" *ngIf="method == type.email">
            Enter your details to continue your journey.
          </p>
        </div>

        <form [formGroup]="loginForm" class="col-12">

          <div class=" form-group mt-4 mb-3" *ngIf="method == type.email">
            <div
              [ngClass]="[ (loginForm.controls['email'].touched && loginForm.controls['email'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
              class="mb-3">
              <label for="text" class="label-name fs-14 w-100">
                Email or Phone Number
              </label>
              <input type="text" class="input_field w-100" name="email" required formControlName="email"
                placeholder="Enter Your Email or Phone number" />
              <p class="mat-error text-danger w-100 d-block mb-2 fs-14"
                *ngIf="(loginForm.controls['email'].touched && loginForm.controls['email'].invalid)">
                {{loginForm.controls['email'].errors ? 'Please enter a valid Email/Phone Number' : ''}} </p>
            </div>
            <label for="text" class="label-name fs-14 w-100">
              Password
            </label>
            <div class="position-relative">

              <input [type]="showPassword ? 'text' : 'password'" class="input_field_pass w-100  mb-3" name="email" required
                formControlName="password" placeholder="Enter Your Password" />
              <img [src]="showPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                alt="" class="position-absolute eye-icon cursor" (click)="showPassword = !showPassword">
            </div>
            <p class="mat-error text-danger w-100 d-block fs-14 fvhgfhcgcvghvh"
              *ngIf="((loginForm.controls['password'].invalid && loginForm.controls['password'].touched))">
              {{( (loginForm.controls['password'].errors) || (loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched)) ? 'Please enter a valid Password' : ''}}</p>

            <div class=" mt-2 w-100 text-end">
              <p>
                <a routerLink="/forgot-password"
                  class="fw-600 cursor  d-inline-block d-blue decoration-none fs-14 text-primary">Forgot Password?</a>
              </p>
            </div>
            <div class=" form-group">
              <div class="w-100 d-flex align-items-center justify-content-between mb-3">
                <button class=" px-4 border-0 text-black  btn btn-secondary fs-16 fw-600 w-100" (click)="login()">
                  Log In </button>

              </div>
              <!-- <button class=" px-4 btn btn-secondary light  fw-600  w-100 mb-3" 
                 [disabled]="( loginForm.controls['email'].invalid)" (click)="method ='password'">Enter
                  Password</button> -->
              <button class=" px-4 btn btn-outline btn-color fs-16 fw-600 w-100 mb-3" (click)="logInType(type.OTP)">Generate
                Verification Code</button>
              <div class="row m-0">
                <div class="col p-0">
                  <hr class="w-100">
                </div>
                <div class="col-1 p-0"><span class="w-100 text-center ms-2">or</span></div>
                <div class="col p-0">
                  <hr class="w-100">
                </div>
              </div>
              <div class="mt-3">
              <app-google-sign-in></app-google-sign-in>
              </div>
              <p class="fs-14 fw-400 m-0 text-center">
                Don't have an account?
                <span class="fw-600 link-text cursor text-primary" [routerLink]="'/register'">Sign Up</span>
              </p>

            </div>
          </div>

          <!-- <ng-container *ngIf="method == type.password"> -->
          <!-- <button class=" px-4 border-0 btn btn-primary fs-16 fw-600" (click)="logInType(type.email)">Back</button> -->
          <!-- </ng-container> -->

          <ng-container *ngIf="method == type.OTP">
            <div class="mb-4">
              <p class="fs-16 fw-400 text-secondary m-0">
                A 6-digit verification code has been sent to <span class="fs-16 fw-700">{{(loginForm.controls['email'].value)}}</span>
              </p>
            </div>
            <div class=" form-group mt-2">
              <p class="fs-14 fw-400">Enter Verification Code</p>
              <form [formGroup]="otpForm" action="" (keydown.enter)="verifyOtp()">
                <div class="form-group otp_fields new-otp_fields mb-3 mt-2 d-flex justify-content-between">
                  <input id={{item}} (input)="onInput($event, index); verifyOtp()"
                    (keydown.Tab)="$event.preventDefault()" (keydown.space)="$event.preventDefault()"
                    (keydown.backspace)="onDelete($event , index)" formControlName="{{item}}" type="text" maxlength="1"
                    *ngFor="let item of otpValueGroup; let index = index" />
                </div>

              </form>
            </div>

            <div class="mb-4 " *ngIf="countdown > 0">Didn’t receive the code? {{ formatTime(countdown) }}
              seconds</div>
            <div class="mb-4 " *ngIf="countdown <= 0">Didn’t receive the code? <a class="color-blue cursor" (click)="renewOtp()">Resend Code</a></div>
            

            <div class="w-100 d-flex align-items-center justify-content-center mb-3">
              <button class=" px-4 border-0  btn btn-secondary fs-16 fw-600 w-100" (click)="verifyOtp()">
                Verify</button>
              <!-- <button class=" px-4 border-0 btn btn-primary fs-16 fw-600" (click)="logInType(type.email)">Back</button> -->
            </div>
            <div class="w-100 d-flex align-items-center justify-content-center mb-3">
              <button class=" px-4 btn btn-default light fs-16 fw-600 w-100" (click)="resetForm()">
                Go Back </button>
            </div>
          </ng-container>
          <!--           
          <div class="  mt-2 w-100 text-end">
            <p>
              <a routerLink="/forgot-password" class="fw-600 cursor  d-block d-blue decoration-none fs-14 text-primary">Forgot Password</a>
            </p>
          </div> -->


        </form>


      </div>
      <div class="footer bottom-10 position-absolute"> © Finance Mutual {{_shared.today | date:"yyyy"}}</div>
    </div>
  </div>
</div>