<div class="login-main-wrapper">
    <div class="inner-wrapper d-flex flex-column flex-lg-row">
        <div class="">
            <app-left-layout></app-left-layout>
        </div>
        <div
            class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto">
            <div class="right-content">
                <app-google-translate></app-google-translate>
                <ng-container *ngIf="selectedStep === step.MOBILE_NUMBER">
                    <form [formGroup]="verifyForm" action="" (submit)="verifyPhoneNumber()">
                        <div>
                            <div class="mb-4">
                                <h2 class="fs-36 fw-600 m-0 mb-3">
                                    Enter your phone <br> number
                                </h2>
                                <p class="fs-16 fw-400 text-secondary m-0">
                                    You will receive a 6 digit number to verify your phone number.
                                </p>
                            </div>
                            <div class=" form-group mt-4 mb-3">
                                <div class="d-flex flex-column-reverse custom-form inputContainer position-relative">
                                    <p class="position-absolute" style="left: 15px;top: 43px;">
                                        {{country_data && country_data.mobile_code ? country_data.mobile_code : '+1'}}</p>
                                    <input style="padding-left: 45px;" type="text" [mask]="mask" name="doctor_mobile" required=""
                                        name="doctor_mobile" formControlName="doctor_mobile"
                                        placeholder="Enter Phone number" class="input_field ">
                                    <label for="text" class="label-name">
                                        <span class="content-name f-c-p-3 fs-14"> Phone number </span>
                                    </label>
                                </div>
                                <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                 *ngIf="verifyForm.controls['doctor_mobile'].touched && verifyForm.controls['doctor_mobile'].invalid">
                                    Please enter valid phone number.
                                </p>
                                <!-- <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                    *ngIf="verifyForm.controls['doctor_mobile'].touched && verifyForm.controls['doctor_mobile'].invalid && verifyForm.controls['doctor_mobile'].dirty">
                                    Please enter valid password (should be greater than 10 characters).
                                </p> -->
                            </div>
                            <button class="btn btn-secondary w-100 fw-600" type="submit">Get Otp</button>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="selectedStep === step.VERIFY_OTP">
                    <div class="mb-4">
                        <h2 class="fs-36 fw-600 m-0 mb-3">
                            Verify your mobile <br> number
                        </h2>
                        <p class="fs-16 fw-400 text-secondary m-0">
                            A one-time OTP will be sent to your mobile number <b>(XXX) XXX {{last4Number}}</b> <a (click)="selectedStep = step.MOBILE_NUMBER" class="text-line cursor mx-2">Change</a>
                        </p>
                    </div>

                    <div class=" form-group mt-4 mb-3">
                        <form [formGroup]="otpForm" action="" (submit)="verifyOtp()">
                            <div class="form-group otp_fields new-otp_fields mb-3 mt-2 d-flex justify-content-between">
                                <input id={{item}} (input)="onInput($event , index)"
                                    (keydown.backspace)="onDelete($event , index)" formControlName="{{item}}"
                                    type="text" maxlength="1" *ngFor="let item of otpValueGroup; let index = index" />
                            </div>
                            <button class="btn btn-secondary w-100 fw-600" type="submit">Verify</button>
                        </form>
                    </div>
                    <div *ngIf="countdown > 0">Request new OTP in {{ formatTime(countdown) }} seconds</div>
                    <a *ngIf="countdown <= 0" class="blue-theme-color cursor" (click)="renewOtp()">Renew OTP</a>
                    <!-- <a (click)="selectedStep = step.MOBILE_NUMBER" class="text-line cursor">Change mobile number</a> -->
                </ng-container>
            </div>
        </div>
    </div>
</div>