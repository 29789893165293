import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CaptchaService } from 'src/app/services/captcha.service';
import { localStorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-confirm-page',
  standalone: true,
  imports: [],
  templateUrl: './confirm-page.component.html',
  styleUrl: './confirm-page.component.scss'
})
export class ConfirmPageComponent implements OnInit {
  event_id: any;
  
  constructor(private _captcha: CaptchaService,private apiSer: ApiService, private _local : localStorageService, private _route: ActivatedRoute){}
  ngOnInit(){
    this._route.queryParams.subscribe((res) => {
      this.event_id = res['event_id'];
    })
    this.confirmation();
  }
  public async confirmation(){
    const captcha = await this._captcha.getCaptchaToken();
    const data = {
      event_id : this.event_id,
      captcha : captcha
    };
    this.apiSer.api(data, 'auth/confirm_training', 'post').subscribe((response) => {
      if (response.is_error == 0) {
        if (response.reset_password_token) {
          this._local.customSetItem('token', response.access_token);
          return;
        }
      }
  })
}
}
