import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { localStorageService } from './localstorage.service';
import { SocketsService } from './socket.service';

@Injectable({
  providedIn: 'root'
})

export class MainService implements OnInit {
  public env = environment;
  // user_review_list = [];
  public ip_country_id$: BehaviorSubject<any> = new BehaviorSubject([]);
  public timezones$: BehaviorSubject<any> = new BehaviorSubject([]);
  public country_data$: BehaviorSubject<any> = new BehaviorSubject([]);
  public stripeKey:any;
  public dynamicColorBgStyle = [
    {
      bg: '#E1FDF3',
      color: '#0F6648'
    },
    {
      bg: '#FFC737',
      color: '#000'
    },
    {
      bg: '#FFEFF0',
      color: '#76012C'
    },
    {
      bg: '#E1FDF3',
      color: '#18B557'
    },
  ];
  randomIndex = Math.floor(Math.random() * (this.dynamicColorBgStyle.length - 1));
  plansList: Array<[]>  = [];


  countryWiseRegex = {
    US: {
      regex: /(\d{3})(\d{3})(\d{4})/g,
      group: '$1-$2-$3'
    },
    CA: {
      regex: /(\d{3})(\d{3})(\d{4})/g,
      group: '$1-$2-$3'
    },
    GB: {
      regex: /(\d{4})(\d{5})/g,
      group: '$1 $2'
    },
    AU: {
      regex: /(\d{1})(\d{4})(\d{4})/g,
      group: '$1 $2 $3'
    },
    NZ: {
      regex: /(\d{1})(\d{4})(\d{4})/g,
      group: '$1 $2 $3'
    },
    DE: {
      regex: /(\d{4})(\d{7})/g,
      group: '$1 $2'
    },
    IT: {
      regex: /(\d{3})(\d{7})/g,
      group: '$1 $2'
    },
    FR: {
      regex: /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/g,
      group: '$1 $2 $3 $4 $5'
    },
    ES: {
      regex: /(\d{3})(\d{6})/g,
      group: '$1 $2'
    },
    PL: {
      regex: /(\d{3})(\d{3})(\d{3})/g,
      group: '$1 $2 $3'
    },
    JP: {
      regex: /(\d{2})(\d{4})(\d{4})/g,
      group: '$1 $2 $3'
    },
  };

  getsettings_count: number = 0;
  show_full_view: string | undefined;
  userHash: string | undefined;

  public path_redirect_to = '';
  // public access_info: BehaviorSubject<any> = new BehaviorSubject('');


  constructor(private _local: localStorageService, private apiSer: ApiService, private _router: Router, public _shared: SharedService, private _socket: SocketsService) {
    this.init();
  }

  ngOnInit(): void {}
  // google sign in decoding url
  // decodeJwtResponse(token: string) {
  //   const base64Url = token.split('.')[1];
  //   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   // tslint:disable-next-line:only-arrow-functions
  //   const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
  //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //   }).join(''));

  //   return JSON.parse(jsonPayload);
  // }

  // get google signin creden flow
  async getUserInfo(accessToken: string) {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const userInfo = await response.json();
      return userInfo;
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error; // You might want to handle or log the error accordingly
    }
  }

  registerUser(userInfoData: userInfo) {
    if (!userInfoData.country_id || !userInfoData.currency_id) {
      this._shared.country_data$.subscribe(data => {
        if (data && data.length) {
          userInfoData.country_id = data[0]['country_id'];
          userInfoData.currency_id = data[0]['currency'][0]['currency_id'];
        }
      })
    }
    this.apiSer.api(userInfoData, 'auth/register').subscribe((data) => {
      if (data.is_error == 0) {
        this.checkProfile(data);
        // this._local.customSetItem('token', data.access_token);
        // this._router.navigate(['verify-login']);
      } else {
        // tslint:disable-next-line:quotemark
        this._shared.customPopups(data.original_text, 1);
      }
    });
  }

  // initialise function of reload or first time initialisation
  init() {
    this._shared.device_id();
    if (this._local.customGetItem('user')) {
      let data = {
        access_token: this._local.customGetItem('token'),
      };

      if (data.access_token && data.access_token != null) {
        this.accessTokenLogin();
        this.listAllCards()
      }
      this.apiSer.api(data, 'auth/get_settings', 'get')
        .subscribe((response: any) => {
          if (response.flag === 70 || response.update_message) {
            location.reload();
            return false;
          }
          if (response && response.stripe_pk) {
            this._shared.stripeKey$.next(response.stripe_pk);
            // above code moved to below functional and for new stripe functionality now below function will execute element
            // this.stripeCreateElem();
            
          }
          // Stripe.setPublishableKey(response.stripe_pk);
          // this.messageList = response.popups;
          // this.user_review_list.next(response.user_review_list); // Message List for how did you hear about us?
          // this.government_docs_main.next(response.government_docs); // Message List for how did you hear about us?
          // this.salutations.next(response.salutation);
          this.timezones$.next(response.timezones);
          // this.arProposals.next(response.total_arv2_proposals);
          // if (response.preapproval_leads_object) {
          //   this.preapproval_leads_object.next(response.preapproval_leads_object);
          // }
          this.ip_country_id$.next(response.ip_country_id);
          // if (response.mobile_code_arr && response.mobile_code_arr.length) {
          //   this.mobile_code_arr$.next(response.mobile_code_arr);
          // }
          if (response.language_data && response.language_data.length) {
            this._shared.language_data = response.language_data;
            this._shared.language_data$.next(response.language_data);
          }
          if (response.country_data && response.country_data.length) {
            this._shared.country_data$.next(response.country_data);
          }
          return true;
        });
    } else {
      this._shared.device_id();
    }
    this.Show_grid_full_view();
  }

  listAllCards() {
    this.apiSer.api({},'profile/list_all_cards','get')
      .subscribe((res: any) => {
        let cards = this._shared.sortByFun(res.data, 'is_default');
        let doctor_cards = cards // doctor_cards is initially set with cards fetched through subscription details api. But on add new card here we setting it again so that new card can be set
        // this.cd.detectChanges();
        console.log(doctor_cards,'doctor_cards')
      })
  }

  Show_grid_full_view() {
    if (!this._local.customGetItem('show_full_view')) {
      this._local.customSetItem('show_full_view', 'false');
    }
    this.show_full_view = JSON.parse(JSON.stringify(this._local.customGetItem('show_full_view')));
  }

  matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get("newPassword")?.value;
    const confirm = control.get("confirmPassword")?.value;
    if (password != confirm) { return { 'noMatch': true } }
    return null
  }
  
  // getFinanceValues(docId: string, type?: string) {
  //   const data = {
  //     access_token: this._local.customGetItem('token'),
  //     doctor_id: docId,
  //     from_cache: 0,
  //     contract_type: type === 'tenPercent' ? 1 : type === 'inHouse' ? 3 : type === 'easyFinance' ? 4 : type === 'deferredPlan' ? 6 : 2
  //   };

  //   this.apiSer.api(data, `get_can_finance_status`)
  //     .subscribe(response => {
  //       if (response.is_error) {
  //         this.financeData$.next(null);
  //         // this.customPopups(response.override_text, 1);
  //       } else {
  //         this._local.customSetItem('getFinanceValues', JSON.stringify(response));
  //         this._local.customSetItem('doctorProfile', JSON.stringify(response.data));
  //         this.financeData = response;
  //         this.financeData$.next(response);
  //       }
  //     });
  // }

  checkProfile(userData: any) {
    this._local.customSetItem('token', userData.access_token);
    if (userData.doctor_profile) {
      this._local.customSetItem('userProfileData', userData.doctor_profile);
      this._shared.doctorProfile$.next(userData.doctor_profile);
    }
    if (userData && userData.mobile_required == 1) {
      this._router.navigate(['verify-phone-number']);
    } else if (userData && userData.ind_id_required == 1) {
      this._router.navigate(['business-info']);
    } else {
      if (userData.country_currency_info) {
        if (userData.country_currency_info && userData.country_currency_info.country) {
          this._shared.country_currency_info$.next(userData.country_currency_info);
        }
      }
      if (userData && userData.doctor_settings) {
        this._local.customSetItem("doctorSettings", userData.doctor_settings);
      }
      this._shared.authenticateApiResponse$.next(userData);
      this._socket.connect();
      this._router.navigate(['app', 'dashboard']);
    }
  }

  accessTokenLogin() {
    this.apiSer.api({}, 'auth/access_token_login')
      .subscribe((response: any) => {
        if (response.flag === 116) {
          this._local.customSetItem('reset_password_token', response.reset_password_token);
          this._router.navigate(['new-password']);
        } else if (!response.is_error) {
          if (response['redirectUrl'] && response['redirectUrl'].url) {
            this._router.navigate([response['redirectUrl'].url], { queryParams: response['redirectUrl'].query });
          } else {
            // proceed to dashboard
            if (response.doctor_profile) {
              this._local.customSetItem('userProfileData', response.doctor_profile);
              this._shared.doctorProfile$.next(response.doctor_profile);
            }
            // if user has just registered but not completed all steps then first check mobile validation then industry validation
            if (response.mobile_required) {
              this._router.navigate(['verify-phone-number']);
              return;
            } else if (response.ind_id_required) {
              this._router.navigate(['business-info']);
              return;
            } else {
              if (response.country_currency_info) {
                this._shared.country_currency_info$.next(response.country_currency_info);
              }
              if (response.bank_details) {
                this._shared.bank_details$.next(response.bank_details)
              }
              if (response.auth_docs) {
                this._shared.auth_docs$.next(response.auth_docs)
              }
              if (response.stripe_data && response.stripe_data.verification && response.stripe_data.verification.status) {
                this._shared.identity_status$.next(response?.doctor_profile?.business_verification_state);
              }

              if (response.menu && response.menu.length > 0 && response.menu.filter((menu: any) => menu.path.includes('dashboard')).length == 0) {
                // this.is_from_login = true;
                this._router.navigate(['app', 'finance-customer']);
              } else {
                if (window.location.pathname.includes('business-info') || window.location.pathname.includes('verify-phone-number')) {
                  this._router.navigate(['app', 'dashboard']);
                }
              }
              this._socket.connect();
            }
          }
          if (response.user_hash) {
            this.userHash = response.user_hash;
          }
          this._shared.authenticateApiResponse$.next(response);
        }
      });
  }
}


export interface userInfo {
  doctor_email: string,
  doctor_password: string,
  doctor_name: string,
  country_id: number,
  currency_id: number,
  doctor_image: any,
  google_id?: string
  
}