import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { localStorageService } from 'src/app/services/localstorage.service';
import { MainService } from 'src/app/services/main.service';
import { SocketsService } from 'src/app/services/socket.service';
enum NavType {
  All = 0,
  Unread = 2,
  Type = 1,
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
  NavType = NavType;
  notifications: any[] = [];
  search_string = '';
  type = 0;
  filter = 0;
  type_Arr = ['Type', 'Payment Plans', 'Claims', 'Payouts','','Leads']
  selected_nav = 1;
  page_number = 1;
  totalRecords = 0;
  // locationFilter: any;
  // locationSubscription: any;
  userInfo: any;
  popup = false;
  doctor_id: any;
  notification_list: any;
  notificationTypes = [
    { id: 2, label: 'Claims' },
    { id: 3, label: 'Payouts' },
    { id: 5, label: 'Leads' }
];
  constructor(public _main: MainService, public _router: Router, public _routes: ActivatedRoute, public _socket: SocketsService, private _api: ApiService, private _local: localStorageService ) { } //public dialogRef: MatDialogRef<NotificationsComponent>

  ngOnInit() {
    this.getNotifications();
    this.userInfo = this._local.customGetItem('user') ? JSON.parse(this._local.customGetItem('user')): null;
    if (this.userInfo && this.userInfo[0] && this.userInfo[0].role_id == 5) {
      // this.locationFilter = this.globalFilterService.getFilterObj('location');
      // this.locationSubscription = this.globalFilterService.locationFilter
      //   .subscribe((filter: any) => {
      //     this.locationFilter = filter;
      //     this.getNotifications();
      //   });
    }
    this.filter = 0;
    this.type = 0;
    this._socket.on('new_notification', (data: any) => {
      this._main.unread_count = data.unread_count;
      console.log(this._main.unread_count , this._main.unread_count);
      
      if (data.data) {
        if (data.data.length > 10) {
          this.notifications = data && data.data.slice(0, 10);
        } else {
          this.notifications = data && data.data;
        }
      }
      this.totalRecords = data.count;
      this.notifications.forEach(element => {
        element.show_sent_at = this._main.covertDateTimesAgo(element.sent_at)
      });
      this.page_number = 1;
    })
    setInterval(() => {
      this.scrollHandler()
    }, 1000)
    if (this.userInfo && this.userInfo[0] && this.userInfo[0].doctor_id) {
      this.doctor_id = this.userInfo[0].doctor_id;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDropdownOpen']?.currentValue) {
      this.getNotifications();
    }
  }

  api_hitting = false;
  scrollHandler() {
    var element = document.querySelector('#infinite-scroller');
    if (element) {
      var position = element.getBoundingClientRect();
      if ((position.top >= 0 && position.bottom <= window.innerHeight) || (position.top < window.innerHeight && position.bottom >= 0)) {
        if (this.totalRecords != this.notifications.length && this.api_hitting == false) {
          this.page_number = this.page_number + 1;
          this.getNotifications();
        }
      }
    }
  }

  getNotifications(type?:number) {
    let params: any = {
      limit: 10,
      offset: (this.page_number - 1) * 10,
      hideLoader: true
    };
    // if (this.locationFilter && this.locationFilter.id) {
    //   params['doctor_id'] = this.locationFilter.id;
    // }
    if (type != 0){
      params['type'] = type;
    }

    if (this.filter){
      params['filter'] =  this.filter != 0 ? this.filter : '';
    }

    if (this.search_string){
      params['search_string'] = this.search_string;
    }
    this.api_hitting = true;
    this._api.api(params, 'profile/fetch_business_notifications', 'get')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (this.page_number == 1) {
            this.notifications = data.data;
          } else {
            this.notifications = [...this.notifications, ...data.data];
          }
          this.notification_list = this.notifications;
          this.totalRecords = data.count;
          this.notifications.forEach(element => {
            element.show_sent_at = this._main.covertDateTimesAgo(element.sent_at)
          });
        }
        this.api_hitting = false;
      })
  }

  readNotification(id?: any) {
    let params: any = {
    };
    if (id) {
      params['bn_id'] = id;
    }
    // if (this.locationFilter && this.locationFilter.id) {
    //   params['doctor_id'] = this.locationFilter.id;
    // }
    this._api.api(params, 'profile/read_business_notifications')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.page_number = 1; this.type = 0; this.filter = 0;
          this.getNotifications();
        }
      })
  }

  navToNotification(notification: any) {
    if (notification && notification.read_at == null) {
      this.readNotification(notification.bn_id);
    }
    switch (notification.type) {
      case 1:
        // contract
        let contract
        if (typeof notification.metadata == 'string') {
          contract = JSON.parse(notification.metadata);
        }
        else {
          contract = notification.metadata;
        }
        this._router.navigate([`/app/contracts/details/${contract.contract_id}`]);

        break;
      case 2:
        // claim
        let claim
        if (typeof notification.metadata == 'string') {
          claim = JSON.parse(notification.metadata);
        }
        else {
          claim = notification.metadata;
        }
        if (claim && claim.redirect_to_contract && claim.contract_id) {
          this._router.navigate([`/app/contracts/details/${claim.contract_id}`]);
        } else {
          this._router.navigate([`/app/claims/details/${claim.contract_id}`]);
        }
        break;
      case 3:
        // payout
        let payout
        if (typeof notification.metadata == 'string') {
          payout = JSON.parse(notification.metadata);
        }
        else {
          payout = notification.metadata;
        }
        if (notification && notification.pn_id == 21) {
          this._router.navigate(['/app/profile']);
        } else {
          this._router.navigate(['/app/reports/payout-details'], { queryParams: { payoutId: payout.payout_id, locationId: this.doctor_id } });
        }
        break;
      case 4:
        this._router.navigate(['/app/profile'], { queryParams: { tab: 'subscription' } });
        break;
      case 5: {
        let lead_meta_data: any = {};
        if (typeof notification.metadata == 'string') {
          lead_meta_data = JSON.parse(notification.metadata);
        }
        else {
          lead_meta_data = notification.metadata;
        }
        this._router.navigate(['app/leads/details'], { queryParams: { application_id: lead_meta_data['application_id'] } });
        break
      }
    }
    this._main.showNotificationBar = false;
  }
  toggleNotificationBar() {
    this._main.showNotificationBar = !this._main.showNotificationBar;
}

updateNotifications(nav: NavType, type?: any) {
  this.filter = nav
  this.selected_nav = nav;
  if (nav != this.NavType.Type) {
      this.type = 0
      this.getNotifications()
    }else{

      if (nav == this.NavType.Type && !type) {
        this.filter = nav
      } else {
        this.getNotifications(type.id)
      }
    }
}

clearSearch() {
    this.search_string = '';
    this.updateNotifications(this.selected_nav);
}
}
