import { Injectable } from "@angular/core";
import { md5 } from "./md5.service";
import { ToasterService } from "../shared/toaster/toaster.service";
import { localStorageService } from "./localstorage.service";
import { BehaviorSubject, Subject } from "rxjs";
declare let Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public country_data$: BehaviorSubject<any> = new BehaviorSubject({});

  // this element used to create stripe element from stripe key.
  public stripeElement: any;

  public dateFormating: any = {
    US: {
      specialCharacters: ['/'],
      fieldOrder: [{
        key: 'month',
        placeholder: 'MM'
      },
      {
        key: 'day',
        placeholder: 'DD'
      },
      {
        key: 'year',
        placeholder: 'YYYY'
      },]
    },
    CA: {
      formatting: '0000-M0-d0',
      placeHolder: 'YYYY-MM-DD',
      specialCharacters: ['-']
    },
    GB: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    AU: {
      formatting: 'd0-M0-0000',
      placeHolder: 'DD-MM-YYYY',
      specialCharacters: ['-']
    },
    NZ: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    FR: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    DE: {
      formatting: 'd0.M0.0000',
      placeHolder: 'DD.MM.YYYY',
      specialCharacters: ['.']
    },
    IT: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    JP: {
      formatting: '0000/M0/d0',
      placeHolder: 'YYYY/MM/DD',
      specialCharacters: ['/']
    },
    PL: {
      formatting: 'd0.M0.0000',
      placeHolder: 'DD.MM.YYYY',
      specialCharacters: ['.']
    },
    ES: {
      formatting: 'd0-M0-0000',
      placeHolder: 'DD-MM-YYYY',
      specialCharacters: ['-']
    },
  };

  public contractType: any = {
    6: 'Protected Ez Plan',
    2: 'Protected No Fee'
  }

  public downloadType = {
    DOWNLOAD: 0,
    PRINT: 2
  }

  public defferedMonths = 12;

  public contractTypes = {
    noFee: 2,
    ez_with_deffered: 6
  };

  public stripeKey$: BehaviorSubject<any> = new BehaviorSubject('');
  public language_data: languageData[] = [];
  public language_data$: BehaviorSubject<any> = new BehaviorSubject([]);

  public country_currency_info$: BehaviorSubject<any> = new BehaviorSubject([]);
  public search_emitter = new Subject<any>();

  public bank_details$: BehaviorSubject<any> = new BehaviorSubject({});
  public auth_docs$: BehaviorSubject<any> = new BehaviorSubject({});
  public identity_status$: BehaviorSubject<any> = new BehaviorSubject({});
  public authenticateApiResponse$: BehaviorSubject<any> = new BehaviorSubject({});
  public doctorProfile$: BehaviorSubject<userProfile> = new BehaviorSubject({
    doctor_email: '',
    doctor_name: ''
  });

  public paymentSourceType = {
    CARD: 3,
    BANK: 1
  }

  public paymentMode = {
    BUSINESS: 2,
    CUSTOMER: 1
  }

  key_sort_by: any;
  signaturePadBase64 = '';
  
  identityStatus = {
    verified: 'identity_verified'  ,
    business_verified:'business_verified',
    unverified: 'unverified',
  }
  public dynamicColorBgStyle = [
    { bg: '#E1FDF3', color: '#0F6648' },
    { bg: '#FFC737', color: '#000' },
    { bg: '#FFEFF0', color: '#76012C' },
    { bg: '#E1FDF3', color: '#18B557' },
    { bg: '#f0f0f0', color: '#0F6648' },
    { bg: '#eaf2ff', color: '#0F6648' },
    { bg: '#f8e7dc', color: '#0F6648' },
    { bg: '#f0ffe0', color: '#0F6648' },
    { bg: '#fff0f5', color: '#0F6648' },
  ];

  randomIndex = Math.floor(Math.random() * (this.dynamicColorBgStyle.length - 1));

  socketIds = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    DOCTOR_CONNECTED: 'DOCTOR_CONNECTED',
    DOCTOR_LOGOUT: 'DOCTOR_LOGOUT',
    CONTINUE_FINANCE_ON_BUSINESS: 'CONTINUE_FINANCE_ON_BUSINESS'
  };

  risk_type = {
    HIGH_RISK_FINANCING: 'HIGH_RISK_FINANCING',
    SECURE_FINANCING: 'SECURE_FINANCING',
    NOT_ELIGIBLE: 'NOT_ELIGIBLE'
  }

  constructor(private _toaster: ToasterService, private _local: localStorageService) {
    this.stripeKey$.subscribe(stripeKey => {
      if (stripeKey) {
        this.stripeElement = Stripe(stripeKey);
      }
    })
  }

  customPopups(message: string, flag: Number) {
    if (flag == 1) {
      this._toaster.showToaster('Error', message, 'error');
    } else if (flag == 2) {
      this._toaster.showToaster('Warning', message, 'warning');
    } else {
      this._toaster.showToaster('Success', message, 'success');
    }
  }

  crypto(tokenFromUI: string) {
    return md5(tokenFromUI);
  }

  updateMasking(format: '') {
    return format.replace(/#/g, '0');
  }

  guid() {
    const a = new Date();
    const nav = window.navigator;
    const screen = window.screen;
    let guid = nav.mimeTypes.length.toString();
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    guid += a.getTime();
    return guid;
  }

  device_id() {
    if (!this._local.customGetItem('user')) {
      const guidLog = this.guid();
      this._local.customSetItem('user', guidLog);
    }
  }

  getCurrentTimezone() {
    let time = new Date().getTimezoneOffset();
    let hours = Math.abs(Math.trunc(time / 60));
    let minutes = ("0" + time % 60).slice(-2);
    let user_timezone = '';
    if (time > 0) {
      user_timezone = "-" + hours + minutes;
    } else {
      user_timezone = "+" + hours + minutes;
    }
    return user_timezone;
  }

  async base64ToBlob(base64String: string) {
    const dataURI = base64String;
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {
      type: 'image/jpeg'
    });
    return blob;
  }

  sortByFun(data: Array<[]>, key_sort_by: string): [][] {
    this.key_sort_by = key_sort_by;
    let sortedData: any;
    if (data) {
      sortedData = data.sort((a: any, b: any) => a[key_sort_by] - b[key_sort_by]);
    }
    return sortedData;
  }

  compare(a: any, b: any) {
    if (a && a[this.key_sort_by] < b && b[this.key_sort_by])
      return -1;
    if (a && a[this.key_sort_by] > b && b[this.key_sort_by])
      return 1;
    return 0;
  }

  updatePanelLanguage(lan_code: string) {
    const value = `/en/${lan_code}`;
    this._local.customSetItem('lan_code', lan_code);
    if (this._local.getCookie('googtrans') && this._local.getCookie('googtrans') == value) {
      return;
    }
    const anchorElement = document.querySelector(`a[data-gt-lang="${lan_code}`);
    setTimeout(() => {
      if (anchorElement instanceof HTMLElement) {
        anchorElement.click();
      }
    }, 1000);
  }

  // Check if the date is valid
  isValidDate(dobData: dobData): boolean {
    if (!dobData) {
      return false;
    }
    const monthNum = parseInt(dobData.month, 10);
    const dayNum = parseInt(dobData.day, 10);
    const yearNum = parseInt(dobData.year, 10);

    // Create a date object
    const date = new Date(yearNum, monthNum - 1, dayNum);

    // Check if the date object matches the input date
    const dates = date.getFullYear() === yearNum && date.getMonth() === monthNum - 1 && date.getDate() === dayNum;
    return dates;
  }

  restrictSpaces(value: string) {
    return value.replace(/\s+/g, ''); // Replace all spaces
  }

  isWeekend(date: Date) {
    const day = date.getDay();
    return (day === 6 || day === 0); // 6 is Saturday, 0 is Sunday
  }

}

export interface dobMask {
  specialCharacters: string[],
  fieldOrder: [
    {
      key: string,
      placeholder: string
    },
    {
      key: string,
      placeholder: string
    },
    {
      key: string,
      placeholder: string
    },
  ]
}

interface languageData {
  created: string,
  is_active: number,
  lan_code: string,
  lan_name: string,
  original_lan_name: string
}

export interface paymentSourceType {
  brand: string,
  exp_month: number,
  exp_year: number,
  last4: string,
  payment_source_type: number,
  source_id: number
}

export interface languageType {
  lan_code: string,
  lan_name: string,
  original_lan_name: string
}

export interface dobData {
  month: string,
  day: string,
  year: string
}

export interface userProfile {
  doctor_name: string,
  doctor_email: string
}
