import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { getDateSuffix, SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-tila-closure',
  templateUrl: './tila-closure.component.html'
})
export class TilaClosureComponent {
  contentData: any;
  token = '';
  start_date = '';

  constructor(private _router: ActivatedRoute, public _apiService: ApiService, public _shared: SharedService, private sanitizer: DomSanitizer) { }
  
  ngOnInit() {
    this._router.queryParams.subscribe(params => {
      console.log('Query Params:', params); // Add this to debug
      this.token = params['token'];
      this.start_date = params['start_date'];
    });
    
    
    if(this.token){
      this.getTilaDetails(this.token, this.start_date);
    }
  }

  
  public getTilaDetails(token: string, start_date: string) {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
  
    const agreement_date = `${month}/${day}/${year}`;
    const start_date_new = new Date(start_date).getDate() + getDateSuffix(new Date(start_date).getDate()) + " of each month" || "Monthly";

    this._apiService.api('' , `support/get_closure_html?token=${token}&start_date=${start_date_new}&agreement_date=${agreement_date}`, "get" )
      .subscribe(response => {
        if (response.is_error) {
        } else {
          if(response.template_content){
            // this.contentData = response.template_content;
            this.contentData = this.sanitizer.bypassSecurityTrustHtml(response.template_content);
          }
        }
      });
  }
}
