import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private isLoaded = false;
  private readonly appId = environment.intercomAppId; // Replace with your actual Intercom App ID

  constructor() {}

  loadIntercom(): void {
    if (this.isLoaded || !this.appId) return;

    const script = document.createElement('script');
    script.src = `https://widget.intercom.io/widget/${this.appId}`;
    script.async = true;
    script.onload = () => {
      this.isLoaded = true;
      this.initialize();
    };

    document.head.appendChild(script);
  }

  initialize(userData?: any): void {
    if (!this.isLoaded) {
      this.loadIntercom();
      return;
    }

    window.Intercom('boot', {
      app_id: this.appId,
      ...userData,
    });
  }

  updateUser(userData: any): void {
    if (this.isLoaded) {
      window.Intercom('update', userData);
    }
  }

  show(): void {
    if (this.isLoaded) {
      window.Intercom('show');
    }
  }

  hide(): void {
    if (this.isLoaded) {
      window.Intercom('hide');
    }
  }

  shutdown(): void {
    if (this.isLoaded) {
      window.Intercom('shutdown');
    }
  }
}
