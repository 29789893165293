<div class="login-main-wrapper">
    <div class="inner-wrapper d-flex flex-column flex-lg-row overflow-hidden">
        <div class="">
            <app-left-layout></app-left-layout>
        </div>
        <div
            class="psoition-relative register-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto">
            <div class="right-content p-3 p-lg-0">
                <!-- <app-google-translate></app-google-translate> -->
                <div class="mb-4" *ngIf="selectedStep === step.REGISTER">
                    <h2 class="fs-36 fw-600 m-0 mb-3">
                        Welcome to <br> Finance Mutual
                    </h2>
                    <p class="fs-16 fw-400 text-secondary m-0">
                        Please fill in your details to get started.
                    </p>
                </div>

                <div class="" *ngIf="selectedStep == step.REGISTER">
                    <form class="h-100" (submit)="submitForm()">
                        <div class=" pb-0 h-100 flex-wrap register-business-div">

                            <ng-container [formGroup]="signUpForm">
                                <div class=""
                                    [ngClass]="[(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && signUpForm['controls']['doctor_name'].touched && signUpForm['controls']['doctor_name'].invalid  ? ' inputContainer custom-form ' : 'custom-form']">
                                    <label for="text" class="label-name w-100 fs-14"><span
                                            class="content-name f-c-p-3 fs-14">
                                            Name* </span>
                                    </label>
                                    <input type="text" class="input_field w-100" name="doctor_name" autocomplete="off"
                                        required name="doctor_name" trim="true" maxlength="30"
                                        formControlName="doctor_name" placeholder="Enter Your Name" />
                                </div>
                                <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                    *ngIf="(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && (signUpForm['controls']['doctor_name'].touched  && signUpForm['controls']['doctor_name'].invalid && !signUpForm['controls']['doctor_name'].dirty)">
                                    Please enter name.
                                </p>
                                <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                    *ngIf="(signUpForm && signUpForm['controls'] && signUpForm['controls']['doctor_name']) && (signUpForm['controls']['doctor_name'].touched  && signUpForm['controls']['doctor_name'].invalid  && signUpForm['controls']['doctor_name'].dirty)">
                                    Please enter valid name.
                                </p>

                                <div class=" form-group pr-md-0 mt-3">
                                    <div class=""
                                        [ngClass]="[ signUpForm && (signUpForm['controls']['doctor_email'].touched && signUpForm['controls']['doctor_email'].invalid || (signUpForm['controls']['doctor_email'] && signUpForm['controls']['doctor_email']['errors'])) ? 'custom-form inputContainer' : 'custom-form ']">
                                        <label for="text" class="label-name fs-14 w-100">
                                            Email*
                                        </label>
                                        <input type="text" class="input_field w-100" required name="doctor_email"
                                            maxlength="50" trim="true" formControlName="doctor_email" autocomplete="off"
                                            placeholder="Enter Your Email" />
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf=" ((signUpForm['controls']['doctor_email'].touched || signUpForm['controls']['doctor_email'].dirty) && signUpForm['controls']['doctor_email'].invalid)">
                                        Please enter valid email address.
                                    </p>
                                </div>

                                <div class=" form-group pr-md-0 mt-3">
                                    <div class=" position-relative"
                                        [ngClass]="[signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid  ? '  b-10 m-h-56 custom-form inputContainer' : ' b-10 m-h-56 custom-form']">
                                        <label for="text" class="label-name fs-14 w-100">
                                            Password*
                                            <span style="
                                    box-shadow: 1px 1px 5px #000;
                                " class="position-absolute d-block bg-white px-3 py-3 mnw-300 info-tooltip border-tooltip radius-16"
                                        *ngIf="showPassTooltip">
                                        <p style="padding: 0 0 0 12px !important;margin-bottom: 0px; font-size: 16px;"
                                            class="m-0 fs-12 fw-800 fs-12 m-0 p-0">
                                            <!-- {{!passwordStrength || passwordStrength === 'Empty'? 'Password Strength Indication' : passwordStrength}} -->
                                            Password must contain
                                        </p>
                                        <div class="d-flex align-items-center justify-content-between my-2"
                                            style="padding-left: 10px;">
                                            <!-- <ng-container *ngFor="let item of [].constructor(4); let i = index">
                                                <span class="w-52 mx-1"
                                                    [ngClass]="((i < totalStrength) && ((totalStrength === 1)) ? 'border-orange' : (((i < totalStrength) && (totalStrength === 2))? 'border-orange' : (((i < totalStrength) && (totalStrength === 3))? 'border-green' : (((i < totalStrength) && (totalStrength === 4))? 'border-green' : 'border-top'))))">
                                                </span>
                                            </ng-container> -->
                                        </div>
                                        <ul class="tooltip-list" style="text-align: left;font-size: 14px;">
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasMaxChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasMaxChar}"><img
                                                        [src]="hasMaxChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Atleast 6 characters</li>
                                            <li style="display: flex;align-items: start;"
                                            [ngClass]="{'text-decoration-line-through color-167': hasUpperCase}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasUpperCase}"><img
                                                        [src]="hasUpperCase ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Upper and lower case</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasSpecialChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasSpecialChar}"><img
                                                        [src]="hasSpecialChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Add a symbol (e.g.!&#64;#$%^&*)</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasNumber}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasNumber}"><img
                                                        [src]="hasNumber ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Include a number (0-9) </li>
                                        </ul>
                                    </span>
                                        </label>
                                        <div class="position-relative">
                                            <input [type]="!hidePwd ? 'password' : 'text'" class="input_field_pass w-100"
                                                name="password" formControlName="doctor_password" autocomplete="off"
                                                required placeholder="Enter Your Password" required (focus)="showPassTooltip = true" (blur)="showPassTooltip = false" />
                                            <img [src]="hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                                alt="" class="position-absolute eye-icon cursor"
                                                (click)="hidePwd = !hidePwd">
                                        </div>
                                    </div>

                                    <!-- <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf="signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid && !signUpForm['controls']['doctor_password'].dirty">
                                        Please enter password.
                                    </p> -->
                                    <!-- <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf="signUpForm['controls']['doctor_password'].touched && signUpForm['controls']['doctor_password'].invalid && signUpForm['controls']['doctor_password'].dirty">
                                        Please enter valid password.
                                    </p> -->
                                </div>
                                <!-- (should be greater than 6 characters). -->
                                <!-- <p class="reffer-code my-2 fs-14 fw-500 cursor blue-theme-color" *ngIf="!referral_code"
                                    (click)="showReferralModal()">
                                    I have partner referral code
                                </p> -->
                                <div class="d-flex align-items-center justify-content-between" *ngIf="referral_code">
                                    <p
                                        class="reffer-code green my-2 fs-14 fw-500 cursor d-flex align-items-center justify-content-between">
                                        <img src="assets/images/icons/reffer-icon.svg" alt="" class="me-2"> Referral
                                        Code {{referral_code}} added
                                    </p>
                                    <img src="assets/images/icons/red-delete-icon.svg" alt="" *ngIf="referralCodeExist"
                                        class="cursor" (click)="RemoveReferralCode()">
                                </div>

                                <div class=" py-2">
                                    <div class="label-name pointer mb-0 fs-500 fs-14 d-flex align-items-start" style="color: #475467;">
                                            <input type="checkbox" class="pointer primary-checkbox"
                                                formControlName="TnC" id="terms">
                                                <label for="terms" class="mr-1 label-new-m mb-0 fs-14"></label>
                                        <div >
                                            <label for="terms" class="mr-1 label-new-m mb-0 fs-14"> <span>By signing up,
                                                you
                                                agree to our</span></label>
                                            <a target="_blank" class="fw-500 blue-theme-color" tappable
                                                style="text-decoration: none" title="Terms & Conditions" href="{{environment.url}}/terms-and-conditions/
                                            " class="fs-14">Terms & Conditions</a>
                                            and
                                            <a target="_blank" class="fw-500 blue-theme-color" tappable
                                                style="text-decoration: none" title="Terms & Conditions" href="{{environment.url}}/privacy-policy/
                                            " class="fs-14">Privacy Policy.</a>
                                        </div>
                                    </div>

                                    <p class="mat-error mb-0 ml-0 pl-0 text-danger fs-14"
                                        *ngIf="signUpForm['controls']['TnC'].touched && !signUpForm['controls']['TnC'].value">
                                        Please accept Terms and Privacy policy.</p>
                                </div>
                            </ng-container>
                            <div class=" form-group mt-3">
                                <button button-primary-auth type="submit"
                                    class="btn btn-secondary w-100 fw-600 mb-3">Create My Account</button>
                                <!-- (click)="socialSignIn()" -->
                                <app-google-sign-in [country_id]="counCurData.country_id"
                                    [currency_id]="counCurData.currency_id"></app-google-sign-in>
                                <p class="mat-error mb-0 ml-0 pl-0" *ngIf="(signUpForm['errors'])">
                                    {{signUpForm && signUpForm['errors']}}
                                </p>
                            </div>
                        </div>

                        <p class="fs-14 fw-400 m-0 text-center">
                            Already have an account?
                            <span class="fw-500 link-text cursor blue-theme-color" [routerLink]="'/login'">Log In</span>
                        </p>

                    </form>

                </div>

                <ng-container *ngIf="selectedStep === step.VERIFY_OTP">
                    <div class="mb-4">
                        <h2 class="fs-36 fw-600 m-0 mb-3">
                            Verify your Email
                        </h2>
                        <p class="fs-16 fw-400 text-secondary m-0">
                            We have sent a verification code to
                            <b>{{signUpForm.controls['doctor_email'].value}}</b>. Please enter the code below to
                            complete your verification
                        </p>
                    </div>
                    <div class=" form-group mt-4 mb-3">
                        <p class="fs-14 fw-500 text-justify">Enter Verification Code</p>
                        <form [formGroup]="otpForm" action="" (submit)="verifyOtp()">
                            <div class="form-group otp_fields new-otp_fields mb-3 mt-2 d-flex justify-content-between">
                                <div class="">
                                    <input id={{item}} (input)="onInput($event , index)"
                                        (keydown.space)="$event.preventDefault()"
                                        (keydown.tab)="$event.preventDefault()"
                                        (keydown.backspace)="onDelete($event , index)" [formControlName]="item"
                                        type="text" maxlength="1"
                                        *ngFor="let item of otpValueGroup; let index = index" />
                                </div>
                            </div>


                            <div class="mb-2 " *ngIf="countdown > 0">Didn’t receive the code? {{ formatTime(countdown)
                                }}
                                seconds</div>
                            <div class="mb-4 " *ngIf="countdown <= 0">Didn’t receive the code? <a class="color-blue cursor" (click)="renewOtp()">Resend Code</a></div>

                            <button class="btn btn-secondary w-100 fw-600 mt-2" type="submit">Verify Email</button>
                            <button (click)="selectedStep = step.REGISTER" class="btn btn-default w-100 fw-600 mt-2"
                                type="submit">Change Email</button>
                        </form>
                    </div>
                    <!-- <a (click)="selectedStep = step.REGISTER" class="text-line cursor">Change your email</a> -->
                </ng-container>
            </div>
            <div class="footer bottom-10 position-absolute"> © Finance Mutual {{_shared.today | date:"yyyy"}}</div>
        </div>
    </div>
</div>