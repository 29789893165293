<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="">
      <app-left-layout></app-left-layout>
    </div>
    <div
      class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto position-relative">
      <app-google-translate></app-google-translate>
      <div class="right-content  p-3 p-lg-0">
        <div class="mb-4">
          <img src="assets/images/icons/back-arrow-circle.svg" class="cursor mb-4" alt="" *ngIf="method != 'email'" (click)="resetForm()"> 
          <h2 class="fs-36 fw-600 m-0 mb-3">
            Unlock Your <br> Financial World
          </h2>
          <p class="fs-16 fw-400 text-secondary m-0"  *ngIf="method == type.email">
            Welcome back! Please enter your details.
          </p>
        </div>

        <form  [formGroup]="loginForm" class="col-12">

          <div class=" form-group mt-4 mb-3" *ngIf="method == type.email">
            <div
              [ngClass]="[ (loginForm.controls['email'].touched && loginForm.controls['email'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
              class="mb-3">
              <label for="text" class="label-name fs-14 w-100">
                Email or Phone number
              </label>
              <input type="text" class="input_field w-100" name="email" required formControlName="email"  (keydown.enter)="method ='password'"
                placeholder="Enter your Email or Phone number" />
            </div>
            <p class="mat-error text-danger w-100 d-block mb-2 fs-14"
              *ngIf="(loginForm.controls['email'].touched && loginForm.controls['email'].invalid)">
              {{loginForm.controls['email'].errors ? 'Please enter a valid Email/Phone' : ''}} </p>

              <div class=" mt-2 w-100 text-end">
                <p>
                  <a routerLink="/forgot-password"
                    class="fw-600 cursor  d-block d-blue decoration-none fs-14 text-primary">Forgot Password?</a>
                </p>
              </div>
            <div class=" form-group">
            
                <button class=" px-4 btn btn-secondary light  fw-600  w-100 mb-3" 
                 [disabled]="( loginForm.controls['email'].invalid)" (click)="method ='password'">Enter
                  Password</button>
               
                <button class=" px-4 btn btn-outline fs-16 fw-600 w-100 mb-3" (click)="logInType(type.OTP)">Generate
                  Otp</button>
            

              <app-google-sign-in></app-google-sign-in>

              <p class="fs-14 fw-400 m-0 text-center">
                Don't have an account?
                <span class="fw-600 link-text cursor text-primary" [routerLink]="'/register'">Sign up</span>
              </p>

            </div>
          </div>

          <ng-container *ngIf="method == type.password">
            <label for="text" class="label-name fs-14 w-100">
              Password
            </label>
            <input type="password" class="input_field w-100  mb-3" name="email" required formControlName="password"
              placeholder="Enter your Password" />
            <p class="mat-error text-danger w-100 d-block fs-14 fvhgfhcgcvghvh"
              *ngIf="((loginForm.controls['password'].invalid && loginForm.controls['password'].touched))">
              {{( (loginForm.controls['password'].errors) || (loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched)) ? 'Please enter a valid Password' : ''}}</p>

            <div class="w-100 d-flex align-items-center justify-content-between mb-3">
              <button class=" px-4 border-0  btn btn-secondary light fs-16 fw-600 w-100" (click)="login()">
                Sign In </button>
              
              <!-- <button class=" px-4 border-0 btn btn-primary fs-16 fw-600" (click)="logInType(type.email)">Back</button> -->
            </div>
          </ng-container>

          <ng-container *ngIf="method == type.OTP">
            <label for="text" class="label-name fs-14 w-100">
              OTP
            </label>
            <div class="mb-4">
              <p class="fs-16 fw-400 text-secondary m-0">
                  A one-time OTP will be sent to your {{is_email?"email":"mobile number" }}
              </p>
          </div>
            <div class=" form-group mt-4 mb-3">
              <form [formGroup]="otpForm" action="" (keydown.enter)="verifyOtp()">
                  <div class="form-group otp_fields new-otp_fields mb-3 mt-2 d-flex justify-content-between">
                      <input id={{item}} (input)="onInput($event , index)"
                      (keydown.Tab)="$event.preventDefault()"
                      (keydown.space)="$event.preventDefault()"
                          (keydown.backspace)="onDelete($event , index)" formControlName="{{item}}"
                          type="text" maxlength="1" *ngFor="let item of otpValueGroup; let index = index" />
                  </div>
        
              </form>
          </div>

          <div *ngIf="countdown > 0">Request new OTP in {{ formatTime(countdown) }} seconds</div>
          <a *ngIf="countdown <= 0" class="blue-theme-color cursor" (click)="renewOtp()">Renew OTP</a>

            <div class="w-100 d-flex align-items-center justify-content-center mb-3">
              <button class=" px-4 border-0  btn btn-secondary light fs-16 fw-600 w-100" (click)="verifyOtp()">
                Verify Now </button>
             
              <!-- <button class=" px-4 border-0 btn btn-primary fs-16 fw-600" (click)="logInType(type.email)">Back</button> -->
            </div>
          </ng-container>
          <!--           
          <div class="  mt-2 w-100 text-end">
            <p>
              <a routerLink="/forgot-password" class="fw-600 cursor  d-block d-blue decoration-none fs-14 text-primary">Forgot Password</a>
            </p>
          </div> -->


        </form>

       
      </div>
    </div>
  </div>
</div>