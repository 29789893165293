import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { localStorageService } from 'src/app/services/localstorage.service';
// import { ActivatedRoute } from '@angular/router';

declare var google: any;
declare var client: any;
@Component({
  selector: 'app-google-sign-in',
  templateUrl: './google-sign-in.component.html',
  styleUrl: './google-sign-in.component.scss'
})
export class GoogleSignInComponent implements OnInit {
  @Input() country_id: number = 1;
  @Input() currency_id: number = 1;
  public env = environment;
  private client: any;
  dynamicText: any='';
  constructor(private main: MainService, private _local: localStorageService, private _googleAuth: GoogleAuthService) { }

  async ngOnInit() {
    try {
      // const currentRoute = this.route.snapshot.routeConfig?.path;
      await this._googleAuth.initClient('YOUR_GOOGLE_CLIENT_ID');
      this.client = this._googleAuth.getClient();
      if (window.location.href.includes('login')) {
        this.dynamicText = 'Sign in with Google';
      } else if (window.location.href.includes('register')) {
        this.dynamicText = 'Sign Up with Google';
      }
    } catch (error) {
      console.error('Error initializing Google client:', error);
    }
  }

  public socialSignIn(): void {
    if (!this._googleAuth.isGoogleLoaded()) {
      console.error('Google API is not loaded yet');
      return;
    }

    if (this._local.getCookie('g_state')) {
      this._local.deleteCookie('g_state');
    }
    if (this.client && this.client.s && this.client.s.scope) {
      this._googleAuth.googelSignIn();
    }

    this.client = google.accounts.oauth2.initTokenClient({
      client_id: this.env.google_client_id,
      scope: 'email profile openid',
      callback: '',
      use_fedcm_for_prompt: true
    });

    this.client.requestAccessToken();

    this.client.callback = async (tokenResponse: any) => {
      // console.log('login tokenResponse ------', tokenResponse);
      const user = await this.main.getUserInfo(tokenResponse.access_token);
      // console.log('user ------', user);
      if (user.email && user.email_verified) {
        const userData = {
          doctor_name: user.name,
          doctor_email: user.email,
          doctor_image: user.picture,
          country_id: this.country_id,
          currency_id: this.currency_id,
          doctor_password: '',
          google_id: user.id || user.sub
        }
        this.main.registerUser(userData);
      }
    };
  }
}
