import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-generate-stripe-element',
  templateUrl: './generate-stripe-element.component.html',
  styleUrl: './generate-stripe-element.component.scss'
})
export class GenerateStripeElementComponent implements OnInit {

  @Output() returnStripeData: EventEmitter<any> = new EventEmitter();

  // using this intent we create stripe element from stripeElement.
  public stripePaymentIntent: any;

  // this element used to create stripe address element from stripeelement.
  public stripeAddressElement: any;

  // this element used to create stripe payment element from stripeelement.
  public stripePaymentElement: any;

  // this element used to create payment request intent from stripe key for wallets (Gpay, Apple Pay).
  public paymentRequestIntent: any;

  // this element used to create stripe payment request element from stripe key with paymentrequestintent for wallets (Gpay, Apple Pay).
  public paymentRequestLinkElement: any;

  public stripeData!: stripe_required_data;

  public currency_data: any;

  public bankType = false;

  @Output() sendPaymentSourceData: EventEmitter<any> = new EventEmitter();
  constructor(private _api: ApiService, private _shared: SharedService, _main: MainService, public bsModalRef: BsModalRef, private _loader: LoaderService) { }

  ngOnInit() {
    if (this.stripeData.payment_source_type == this._shared.paymentSourceType.CARD) {
      this.bankType = false;
    }
    this._shared.country_currency_info$.subscribe(data => {
      if (data && data.currency && data.currency.length) {
        this.currency_data = data.currency[0];
      }
    })
    // create client secret
    this.getClientSecret();
  }

  getClientSecret() {
    console.log(this.stripeData, 'stripeData')
    const params = {
      contract_id: this.stripeData.patient_profile.contract_id,
      payment_source_type: this.stripeData.payment_source_type,
      payment_mode: this.stripeData.payment_mode,
      action_from: 'MAKE_PAYMENT'
    }
    this._api.api(params, 'finance/create_payment_intent', 'post').subscribe(response => {
      if (response.is_error == 0 && response.client_secret) {
          this.createStripeElmentsDynamic(response.client_secret, response.amount, this.stripeData.patient_profile, this.stripeData.doctor_profile);
      }
    })
  }

  createStripeElmentsDynamic(clientSecret: string, walletAmount: number, patientProfile: any, doctorProfile: any) {
    if (walletAmount) {
      walletAmount = Number(walletAmount) / 100;
    }
    const appearance = {
      theme: 'stripe',
      labels: 'floating'
    };
    const paymentElementOptions = {
      layout: 'tabs',
    };
    
    this.stripePaymentIntent = this._shared.stripeElement.elements({ appearance, clientSecret });
    this.stripePaymentElement = this.stripePaymentIntent.create('payment', paymentElementOptions);


    if(this.stripeData.payment_mode == 1) {
      this.stripeAddressElement = this.stripePaymentIntent.create('address', {
        mode: 'billing',
        defaultValues: {
          name: patientProfile && (patientProfile['patient_first_name'] + ' ' + patientProfile['patient_last_name']) || '',
          email: patientProfile && patientProfile['patient_email'],
          address: {
            line1: patientProfile && patientProfile['patient_address'] || '',
            line2: '',
            city: patientProfile && patientProfile['city'] || '',
            state: patientProfile && patientProfile['state'] || '',
            postal_code: patientProfile && patientProfile['zip'] || null,
            country: patientProfile && patientProfile['country_code'] || 'US',
          },
        },
      });
    } else {
      this.stripeAddressElement = this.stripePaymentIntent.create('address', {
        mode: 'billing',
        defaultValues: {
          name: doctorProfile && doctorProfile['doctor_name']|| '',
          email: doctorProfile && doctorProfile['doctor_email'],
          address: {
            line1: doctorProfile && doctorProfile['doctor_address'] || '',
            line2: '',
            city: doctorProfile && doctorProfile['city'] || '',
            state: doctorProfile && doctorProfile['state_name'] || '',
            postal_code: doctorProfile && doctorProfile['zip'] || null,
            country: doctorProfile && doctorProfile['country_code'] || 'US',
          },
        },
      });
    }
   
    if (walletAmount) {
      // for google and apple pay implementation

      this.paymentRequestIntent = this._shared.stripeElement.paymentRequest({
        country: this.currency_data.country_code,
        currency: this.currency_data.currency_code,
        total: {
          label: 'Total',
          amount: parseFloat(((walletAmount || 1) * 100).toFixed(2)),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['googlePay', 'link']
      });
      this.paymentRequestLinkElement = this._shared.stripeElement.elements().create('paymentRequestButton', {
        paymentRequest: this.paymentRequestIntent,
        style: {
          paymentRequestButton: {
            type: 'default',
            theme: 'dark',
            height: '45px'
          }
        },
      });

      this.paymentRequestIntent.canMakePayment().then((resp: any) => {
        if (resp && (resp.applePay || resp.link)) {
          this.paymentRequestLinkElement.mount('#' + 'payment_request_button');
        } else {
          const button = document.getElementById('payment_request_button');
          if (button) {
            button.style.display = 'none';
          }
        }
      });
    }

    // mount elements to render on html
    this.stripePaymentElement.mount('#payment-element');
    this.stripeAddressElement.mount('#address-element');
  }

  confirmPaymentSetUp() {
    this._loader.showLoader();
    // tslint:disable:quotemark
    const errorMessages = [
      "Your card's expiration date is incomplete.",
      'Your card number is incomplete.',
      "Your card's security code is incomplete.",
      "Your postal code is incomplete.",
      "This field is incomplete.",
      "Your ZIP is invalid."
    ];
    this._shared.stripeElement.confirmSetup({
      elements: this.stripePaymentIntent,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: ''
      },
      redirect: 'if_required'
    }).then((setUpResp: any) => {
      this._loader.hideLoader();
      if (setUpResp.setupIntent && setUpResp.setupIntent.payment_method) {
        const obj = {
          patient_id: this.stripeData.patient_profile.patient_id,
          setup_id: setUpResp.setupIntent.id,
          payment_source_type: this.stripeData.payment_source_type,
          contract_id: this.stripeData.patient_profile.contract_id
        }
        if (this.stripeData.payment_mode == 1) {
          this.addCustomerPaymentSource(obj);
        } else {
          delete obj.patient_id;
          this.addDoctorPaymentSource(obj);
        }
      } else {
        if (setUpResp.error && setUpResp.error.message) {
          this._shared.customPopups(setUpResp.error.message, 1);
        }
        if (setUpResp.error && !errorMessages.includes(setUpResp.error.message)) {
          // this.showElementTrigger.emit(false);
          // reinitialise here
        }
      }
    })
  }

  addCustomerPaymentSource(data?: any) {
    this._api.api(data, 'finance/add_payment_source').subscribe(response => {
      if (!response.is_error) {
        this.sendPaymentSourceData.emit({ selectedCard: response, success: true });
      } else {
        this.sendPaymentSourceData.emit({ success: false });
      }
      this.bsModalRef.hide();
    })
  }

  addDoctorPaymentSource(data?: any) {
    this._api.api(data, 'finance/save_doctor_payment_method').subscribe(response => {
      if (!response.is_error) {
        this.sendPaymentSourceData.emit({ selectedCard: response, success: true });
      } else {
        this.sendPaymentSourceData.emit({ success: false });
      }
      this.bsModalRef.hide();
    })
  }

  modalHide() {
    this.bsModalRef.hide();
  }
}
interface stripe_required_data {
  minHeight?: number;
  patient_profile?: any,
  payment_source_type: number,
  payment_matrix_type?: string,
  payment_mode?: number,
  doctor_profile?: any
}
