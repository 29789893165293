import { Component, Input, OnInit } from '@angular/core';
import { localStorageService } from 'src/app/services/localstorage.service';
import { languageType, SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-google-translate',
  templateUrl: './google-translate.component.html',
  styleUrl: './google-translate.component.scss'
})

export class GoogleTranslateComponent implements OnInit {
  language_data!: languageType[];
  selectedLanguage!: languageType;
  @Input() position = 'position-absolute'
  constructor (private _shared: SharedService, private _local: localStorageService) {}
  ngOnInit(): void {
    this._shared.language_data$.subscribe((data: languageType[]) => {
      this.language_data = data;
      const lan_code = this._local.customGetItem('lan_code');
      if (lan_code) {
        const selectedLang = this.language_data.find((item: languageType) => item.lan_code === lan_code);
        if (selectedLang && selectedLang.lan_code) {
          this.selectedLanguage = selectedLang;
        }
      } else {
        this.language_data.forEach((item: languageType) => {
          if (item.lan_code == 'en') {
            this.selectedLanguage = item;
          }
        })
      }
    })
  }
  
  updatePanelLanguage(lang: languageType) {
    this.selectedLanguage = lang;
    this._shared.updatePanelLanguage(lang.lan_code);
  }
}
