<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="" >
      <app-left-layout></app-left-layout>
    </div>
    <div class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center overflow-lg-auto vh-100"
      >
      <div class="right-content w-100  p-3 p-lg-0">
        <h1 class="fs-36 fw-600 m-0 mb-3" >
          {{'Create a New' }}<span style="color:var(--d-blue)"> {{'Password' }}</span>
        </h1>
        <p class="fs-16 fw-400 text-greyish">Your reset code has been verified. Please set a new password for your account.</p>
        <form class="w-100 " [formGroup]="newPasswordForm" (submit)="submitForm()">
          <div class=" position-relative">
            <label for="text" class=" fs-14 w-100">
              New Password
              <span style="
                                    box-shadow: 1px 1px 5px #000;
                                " class="position-absolute d-block bg-white px-3 py-3 mnw-300 info-tooltip border-tooltip radius-16"
                                        *ngIf="showPassTooltip">
                                        <p style="padding: 0 0 0 12px !important;margin-bottom: 0px; font-size: 16px;"
                                            class="m-0 fs-12 fw-800 fs-12 m-0 p-0">
                                            <!-- {{!passwordStrength || passwordStrength === 'Empty'? 'Password Strength Indication' : passwordStrength}} -->
                                            Password must contain
                                        </p>
                                        <div class="d-flex align-items-center justify-content-between my-2"
                                            style="padding-left: 10px;">
                                            <!-- <ng-container *ngFor="let item of [].constructor(4); let i = index">
                                                <span class="w-52 mx-1"
                                                    [ngClass]="((i < totalStrength) && ((totalStrength === 1)) ? 'border-orange' : (((i < totalStrength) && (totalStrength === 2))? 'border-orange' : (((i < totalStrength) && (totalStrength === 3))? 'border-green' : (((i < totalStrength) && (totalStrength === 4))? 'border-green' : 'border-top'))))">
                                                </span>
                                            </ng-container> -->
                                        </div>
                                        <ul class="tooltip-list" style="text-align: left;font-size: 14px;">
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasMaxChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasMaxChar}"><img
                                                        [src]="hasMaxChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Atleast 6 characters</li>
                                            <li style="display: flex;align-items: start;"
                                            [ngClass]="{'text-decoration-line-through color-167': hasUpperCase}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasUpperCase}"><img
                                                        [src]="hasUpperCase ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Upper and lower case</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasSpecialChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasSpecialChar}"><img
                                                        [src]="hasSpecialChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Add a symbol (e.g.!&#64;#$%^&*)</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasNumber}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasNumber}"><img
                                                        [src]="hasNumber ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Include a number (0-9) </li>
                                        </ul>
                                    </span>
            </label>
            <div class="position-relative">
              <input [type]="hideNewPassword == true ? 'password' : 'text'" class="input_field w-100" name="password"
                formControlName="newPassword" autocomplete="off" required maxlength="75" required (focus)="showPassTooltip = true" (blur)="showPassTooltip = false"
                placeholder="Enter New Password" />
              <img [src]="!hideNewPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                alt="" class="position-absolute eye-icon cursor" (click)="hideNewPassword = !hideNewPassword">
            </div>
            <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
              *ngIf="newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].invalid && !newPasswordForm.controls['newPassword'].dirty">
              Please enter valid password.
            </p>
          </div>
          <div class="position-relative">
            <label for="text" class="fs-14 w-100">
              Confirm Password
            </label>
            <div class="position-relative">
              <input [type]="hideConfirmPassword == true ? 'password' : 'text'" class="input_field w-100"
                name="password" formControlName="confirmPassword" autocomplete="off" required maxlength="75"
                placeholder="Re-Enter Your New Password" />
              <img
                [src]="!hideConfirmPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                alt="" class="position-absolute eye-icon cursor" (click)="hideConfirmPassword = !hideConfirmPassword">
            </div>
          </div>
          <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
            *ngIf="(newPasswordForm.controls['confirmPassword'].touched && newPasswordForm.controls['confirmPassword'].invalid && !newPasswordForm.controls['confirmPassword'].dirty) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'] && newPasswordForm.controls['confirmPassword'].touched )">
            {{ newPasswordForm.errors && newPasswordForm.errors['noMatch'] &&
            newPasswordForm.value['confirmPassword'].length > 0 ? "Passwords did not match." : 'Please enter
            valid password.'}}
          </p>
          <!-- <span class="w-100 d-flex justify-content-end">
            <a (click)="routeBack()" class="fw-600 cursor fs-14"> Back to Login </a>
          </span> -->
        <button button-primary-auth type="submit" class="w-100 border-0 mt-5 btn btn-secondary fs-16 fw-600 "
          >
          Update Password
        </button>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- [disabled]="(newPasswordForm.invalid) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'])" -->