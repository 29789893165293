<div class="layout-wrapper">
  <div class="layout-wrapper-inner vh-100 overflow-hidden">
    <div class="layout-body d-flex h-100">
      <div *ngIf="showSideMenu">
        <app-sidemenu></app-sidemenu>
      </div>
      <div class="right-content flex-fill position-relative">
        <app-layout-header *ngIf="showMainHeader"></app-layout-header>
        <div class="right-side-component">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <app-notifications></app-notifications>
  </div>
</div>