import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpaces]'
})
export class NoSpacesDirective {

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.control.control?.setValue(input.value.replace(/\s+/g, '')); // Replace spaces
  }

}