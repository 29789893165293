import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})

export class ForgotPasswordComponent {
  public env = environment;
  public forgotPasswordForm: UntypedFormGroup;
  hidePwd: boolean = false;
  
  constructor(private fb: UntypedFormBuilder, private apiSer: ApiService, private _router: Router, private _shared: SharedService) {
    this.forgotPasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.maxLength(100)]]
    })
  }

  public submit() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAsDirty();
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    const data = {
      doctor_email: this.forgotPasswordForm.controls['email'].value
    };
    this.apiSer.api(data, 'auth/forgot_password').subscribe((response) => {
      if (response.is_error == 0) {
        this._router.navigate(['login']);
        this._shared.customPopups('Password sent to your mail successfully', 3);
        return;
      } else {
        this.forgotPasswordForm.markAsDirty();
        this.forgotPasswordForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
  }
}
