import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.scss']
})
export class ConsentFormComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, public _apiService: ApiService, public _sharedService: SharedService) { }
  title = '';
  patientFirstName = '';
  patientlastName = '';
  practiceName = ''
  date = '';
  ind_id = '';
  customer_city = '';
  customer_state = '';
  customer_zip = '';
  patient_mobile = '';
  patient_email = "";
  patient_Address = '';
  paramSubscription: Subscription | undefined;
  ngOnInit() {
    if (this.route.snapshot.queryParams['apf_id']) {
      this.getapfInfo(this.route.snapshot.queryParams['apf_id']);
    } else {
      this.paramSubscription = this.route.queryParams
        .subscribe((params: any) => {
          this.title = params.title;
          this.practiceName = params.practiceName;
          this.patientFirstName = params.patientFirstName;
          this.patientlastName = params.patientlastName;
          this.date = params.date;
          this.ind_id = params.ind_id;
          this.patient_mobile = params.patient_mobile;
          this.patient_email = params.patient_email;
          this.patient_Address = params.patient_Address;
          this.customer_city = params.customer_city;
          this.customer_state = params.customer_state;
          this.customer_zip = params.customer_zip;
        });
    }

  }
  ngOnDestroy(): void {
    this.paramSubscription && this.paramSubscription.unsubscribe();
  }
  getapfInfo(apf_id: any) {
    let data = {
      access_token: this.route.snapshot.queryParams['ac_tk'],
      apf_id: apf_id
    }
    this._apiService.api(data, 'apf_info', "get")
      .subscribe((data: any) => {
        if (data && data.data) {
          this.customer_city = data.data.city;
          this.date = data.data.date_added;
          this.ind_id = data.data.ind_id;
          this.patient_Address = data.data.patient_Address;
          this.patient_email = data.data.patient_email;
          this.patientFirstName = data.data.patient_first_name;
          this.patientlastName = data.data.patient_last_name;
          this.patient_mobile = data.data.patient_mobile;
          this.title = data.data.patient_title;
          this.practiceName = data.data.practice_name;
          this.customer_state = data.data.state_name;
          this.customer_zip = data.data.zip;
        }
      });
  }
}
