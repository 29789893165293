import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-common-terms',
  templateUrl: './common-terms.component.html',
  styleUrls: ['./common-terms.component.scss']
})
export class CommonTermsComponent implements OnInit {
  dynamic_html: String = 'Loading...';
  constructor(public _sharedService: SharedService, private _apiService: ApiService, public _routes: ActivatedRoute) { }

  ngOnInit() {
    let template_name = "BUSINESS_MEDICAL_CONTRACT_TERMS";
    if (window.location.pathname == '/paymentProcessTerms') {
      template_name = 'PAYMENT_TERMS';
    }
    if (window.location.pathname == '/contractTerms') {
      template_name = 'BUSINESS_CONTRACT_TERMS';
      console.log(this._routes.snapshot)
      if (this._routes.snapshot.queryParams['ind_id'] == 1 || this._routes.snapshot.queryParams['ind_id'] == 6) {
        template_name = 'BUSINESS_MEDICAL_CONTRACT_TERMS';
      }
    }
    
    switch (this._routes.snapshot.params['term_type']) {
      case 'payment-process-terms':
        template_name = 'PAYMENT_TERMS'
        break;
      case 'business-owner-med-terms':
        template_name = 'TERMS_AND_CONDITION'
        break;
      case 'business-owner-terms':
        template_name = 'BUSINESS_NON_MEDICAL_TERMS_AND_CONDITION'
        break;
      case 'contracts-med-terms':
        template_name = 'BUSINESS_MEDICAL_CONTRACT_TERMS'
        break;
      case 'contracts-terms':
        template_name = 'BUSINESS_CONTRACT_TERMS'
        break;
    }
    this.getCMSHTML(template_name)
  }
  public getCMSHTML(template_name: String) {
    const data = {
      template_name: template_name,
      type: 1
    }
    this._apiService.api(data, `support/cms_details_for_panel`, "post" )
      .subscribe(response => {
        if (response.is_error) {
        } else {
          this.dynamic_html = response.data[0].content;          
        }
      });
  }
}
