import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrl: './referral-code.component.scss',
})
export class ReferralCodeComponent {
  referralCode = '';
  @Output() onReferralCode = new EventEmitter<string>();
  constructor(private bsModalRef: BsModalRef, private route: ActivatedRoute, public _shared: SharedService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.referralCode = params['referral_code'] || '';
    });
  }

  onClose() {
    this.bsModalRef.hide();
  }

  restrictSpaces(value: string) {
    this.referralCode = this._shared.restrictSpaces(value);
    console.log(this._shared.restrictSpaces(value));
    console.log(this.referralCode);
  }


  sendCode() {
    if (!this.referralCode) return;
    this.onReferralCode.emit(this.referralCode);
    this.onClose();
  }
}
