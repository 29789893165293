import { Component, HostListener } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { localStorageService } from 'src/app/services/localstorage.service';
import { MainService } from 'src/app/services/main.service';
import { SharedService } from 'src/app/services/shared.service';
import { LogoutModalComponent } from 'src/app/shared/logout-modal/logout-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrl: './layout-header.component.scss'
})
export class LayoutHeaderComponent {
  settingsList = [
    {
      label: 'Profile Settings',
      icon: 'assets/images/icons/user-icon.svg',
      path: 'app/profile',
      shoAction:true
    },
    // {
    //   label: 'Financial Settings',
    //   icon: 'assets/images/icons/users-icon.svg',
    //   path: 'app/financial-settings'
    // },
    {
      label: 'Manage Users',
      icon: 'assets/images/icons/users.svg',
      path: 'app/manage-users',
      shoAction:true
    },
    {
      label: 'Support',
      icon: 'assets/images/icons/support-icon.svg',
      path: 'app/support',
      shoAction:true
    },
    {
      label: 'Disputes',
      icon: 'assets/images/icons/shield-dollar.svg',
      path: 'app/disputes',
      shoAction:true
    }
  ];
  userProfile: any;
  bsModalRef: BsModalRef | undefined;
  showLogout = false;
  search_string = '';
  isDropdownOpen = false;
   showDebtBtn = false;
   private routerSubscription!: Subscription;
  constructor(private modalService: BsModalService, private _local: localStorageService, private _router: Router, public _shared: SharedService, public _main: MainService
  ) {
    this.userProfile = this._local.customGetItem('userProfileData');
    if (this.userProfile && this.userProfile.doctor_image && this.userProfile.doctor_image.includes('profile_placeholder')) {
      this.userProfile['doctor_image'] = 'assets/images/icons/profile_placeholder.png'
    }
    if (this.userProfile && this.userProfile.role_id == 5) {
      this.settingsList[1].shoAction = false
      this.settingsList[3].shoAction = false
    }
  }

  ngOnInit(): void {
    this._shared.doctorProfile$.subscribe(response => {
      this.userProfile = response;
      if (this.userProfile && this.userProfile.doctor_image && this.userProfile.doctor_image.includes('profile_placeholder')) {
        this.userProfile['doctor_image'] = 'assets/images/icons/profile_placeholder.png'
      }
    })
    this.routerSubscription = this._router.events.subscribe(event => {
      if (this._router.url.includes('debt-recovery/list')) {
      this.showDebtBtn = true
      } else {
        this.showDebtBtn = false
      }
    })
  }

  openNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(0%)';
    }
  }

  logoutModal() {
    const initialState = {
    };
    this.bsModalRef = this.modalService.show(LogoutModalComponent, {
      initialState,
      class: 'modal-dialog-centered'
    });
  }

  showLogoutModal() {
    this.showLogout = true;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(event: Element): void {
    if (!event.classList.contains('lhc') && !event.classList.contains('logout-wrapper') && !event.classList.contains('profile-icon')) {
      this.showLogout = false;
    }
  }

  clearSearch(){
    this.search_string = "";
    this.emitSearch();
  }
  userAction(action: any) {
    this._router.navigate([action.path]);
  }

  emitSearch() {
    // this._shared.search_emitter.next(this.search_string);
  }

  onDropdownToggle(isOpen: boolean) {
    this.isDropdownOpen = isOpen;
  }
  showNotification() {
    const sidenav = document.getElementById('notify-wrapper');
    if (sidenav) {
      sidenav.style.transform = 'translate(0%)';
    }
  }
  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    this.routerSubscription?.unsubscribe();
  }
}
