<div class="overlay position-fixed vh-100 vw-100 top-0 start-0" *ngIf="_main.showNotificationBar">
    <div class="position-absolute top-0 end-0 bg-white h-100 w-100 mxw-450 p-3 notification-wrapper">
        <div class="notify-header">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h4 class="fw-700 m-0">Notifications</h4>
                <img src="assets/images/icons/cross-icon.svg" class="hw-30 border p-1 rounded-circle cursor"
                    (click)="toggleNotificationBar()" alt="Close">
            </div>

            <div class="d-flex align-items-center">
                <p class="fs-14 fw-400 m-0 cursor me-2 px-2 rounded-pill" 
                [ngClass]="selected_nav === NavType.All ? 'text-white bg-success' : 'text-muted'"
 
                   (click)="updateNotifications(NavType.All)">All</p>
                <p class="fs-14 fw-400  m-0 cursor me-2 px-2 rounded-pill" 
                [ngClass]="selected_nav === NavType.Unread ? 'text-white bg-success' : 'text-muted'"

                   (click)="updateNotifications(NavType.Unread)">Unread</p>
                <div class="btn-group" dropdown>
                    <span id="button-animated" class="fs-14 fw-400 cursor px-2 rounded-pill" dropdownToggle  (click)="updateNotifications(NavType.Type)" [ngClass]="selected_nav === NavType.Type ? 'text-white bg-success' : 'text-muted'"
                    >
                       Payment Plans
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="height: 16px;" class="toggle-arrow">
                        <path
                        d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                        [attr.fill]="selected_nav === NavType.Type ? '#fff' : '#000'" />
                        </svg>
                    </span>
                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu">
                        <li *ngFor="let type of notificationTypes" 
                            class="dropdown-item cursor" 
                            (click)="updateNotifications(NavType.Type, type)">
                            {{type.label}}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="d-flex w-100 align-items-center input_field p-0 rounded-pill overflow-hidden mt-3 mb-4">
                <span class="d-flex justify-content-center align-items-center ps-2 hw-40 h-100">
                    <img src="assets/images/icons/search-icon.svg" alt="">
                </span>
                <input class="input_field m-0 border-0 flex-fill" type="text" trim="true" tabindex="-1"
                    (keydown.enter)="page_number = 1;type=0;filter=0; getNotifications();selected_nav=1;" required
                    [(ngModel)]="search_string" placeholder="">
                <span class="d-flex justify-content-center align-items-center pe-2 hw-40 h-100 cursor"
                    *ngIf="search_string">
                    <img src="assets/images/icons/cross-icon.svg" class="cursor" alt="" (click)="search_string = ''">
                </span>
            </div>
        </div>

        <div class="notify-list overflow-auto">
            <div *ngIf="notifications.length > 0; else noNotifications" (scroll)="scrollHandler()">
                <div 
                    class="notification-item border-top p-2 cursor"
                    *ngFor="let item of notifications;let i = index" (click)="navToNotification(item)">
                    <h4 class="fs-16 fw-600 m-0">{{item.title || 'No Title'}}</h4>
                    <p class="fs-14 fw-400 text-muted m-0">{{item.message || 'No Message'}}</p>
                    <span class="fs-12 fw-400 text-muted">
                        <img src="assets/images/icons/clock-icon.svg" class="me-2">{{item.show_sent_at || 'No Date'}}
                    </span>
                </div>
            </div>
            <ng-template #noNotifications>
                <p class="text-center fs-14 fw-400 text-muted">No notifications found.</p>
            </ng-template>
        </div>
        
    </div>
</div>
