<div style="background-image: linear-gradient(to right, #183813 0%, #b6e0ad 100%);">
    <div class="container pt-4 pb-4 mb-4">
        <a href="#"><img src="assets\images\icons\FM-logo.svg" /></a>
    </div>
</div>
<div class="container">
    <div class="row">
        <h3 class="col-lg-4 col-md-4 col-xs-12 col-sm-4 p-0"
            style="text-align: center; margin: auto !important;font-size: 40px;font-weight: 600;">Consent Form</h3>
        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
            <div class="container" id="A4" style="background: white;padding: 30px;max-width:970px">
                <div class="A4"
                    style="height: 1120px;max-width: 850px;margin: auto;display: table;background: rgba(24, 56, 19, 1);">
                    <div class="header"
                        style="text-align:center;height:67px;width: 100%;padding: 0;background-image: linear-gradient(to right, #183813 0%, #b6e0ad 100%);">
                        <img src="assets\images\icons\FM-logo.svg" width="120" style="margin: 20px auto;">
                    </div>
                    <div class="content">
                        <h3
                            style="margin:auto;border-bottom:1px solid grey;width:max-content;text-align:center;font-size: 20px;margin-bottom: 10px;margin-top: 20px;">
                            ACH / Card
                            Payment Authorization</h3>

                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            I, <strong style="padding-bottom: 2px;" *ngIf="patientFirstName"> {{title}}
                                {{patientFirstName}} {{patientlastName}}</strong>
                            <strong *ngIf="!patientFirstName"> ________________________ </strong> authorized signer on
                            this account. I authorize Finance Mutual to make
                            regularly scheduled charges to my Credit Card/Bank account in the amount of my
                            Finance Mutual statement balance for my account, on the payment date indicated. I
                            understand and agree that Finance Mutual will debit my Credit Card/Bank account each
                            month via Automatic Clearing House (ACH) debit, even if I have made other
                            payments between the statement date and the scheduled payment date, including if
                            I paid the statement balance. These charges will appear on my payment methods
                            monthly statement and can be reviewed anytime through the Finance Mutual customer
                            portal.
                        </p>
                        <div style="line-height: 2;padding: 0 15px 15px 15px;margin-bottom: 0px;margin-top: 0px">
                            By enrolling in Credee’ recurring Automatic Monthly Payment Program
                            ("Automatic Monthly Payment Program"), I also acknowledge the following:
                            <ul>
                                <li>If the scheduled date of any electronic funds transfer ("EFT") falls on a
                                    weekend or holiday, my EFT can be initiated as of the next business day.
                                </li>
                                <li>If any EFT is returned or dishonored for any reason, including
                                    non-sufficient funds, a stop payment order, or a closed account, Finance Mutual
                                    may at its discretion attempt to process the charge again. I further agree
                                    to an additional $25 charge for each returned or dishonored EFT which will
                                    be initiated as a separate transaction from the authorized recurring
                                    payment.</li>
                                <li>I agree not to dispute this recurring billing with my financial institution
                                    so long as the transactions correspond to the terms indicated in this
                                    authorization form.</li>
                                <li>I understand that this authorization will remain in effect unless I cancel
                                    it in writing, and I agree to notify legal&#64;financemutual.com in writing of any
                                    changes in my account information or termination of this authorization at
                                    least 15 days prior to the next billing date.</li>
                            </ul>
                        </div>
                        <h4 style="padding: 0 15px;margin-bottom: 0;margin-top: 19px;">Payment for Service
                        </h4>
                        <p style="line-height: 2;padding: 0 15px;margin-top: 0px">I accept that I am
                            financially responsible for all services rendered on my behalf for which a
                            charge may be associated. I accept personal responsibility for all payments,
                            down payments, late fees, plus any collection costs for amounts personally owed
                            by me.</p>
                        <h4 style="padding: 0 15px;margin-bottom: 0;margin-top: 19px;">Unpaid Account
                            Balances</h4>
                        <p style="line-height: 2;padding: 0 15px;margin-bottom: 0px;margin-top: 0px">
                            I acknowledge that if I fail to make payments for services rendered, my account
                            may be turned over to a collection agency. I will be responsible to pay the
                            collection agency’s fees that may be incurred in the collection of any
                            outstanding balance.
                        </p>
                        <h4 style="padding: 0 15px;margin-bottom: 0;margin-top: 19px;">Credit Reporting</h4>
                        <p style="line-height: 2;padding: 0 15px;margin-bottom: 0px;margin-top: 0;">
                            Finance Mutual may report my payment activities to credit bureaus as an installment
                            payment to help build credit. Finance Mutual may also report my defaulted debt to
                            credit bureaus after payment is 31 days or more past due.
                        </p>
                    </div>

                    <div class="footer" style="background: rgba(245, 246, 250, 1.0);">
                        <span>ACH /
                            Card Payment
                            Authorization&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>support&#64;financemutual.com&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>16500
                            Bake Pkwy
                            Irvine, CA 92618</span>
                        <!-- <p style="color:#7f8fa4;font-size: 10px;margin: 0px auto;padding-bottom: 50px">This is system generated statement. If you have any queries, you can reach us anytime at support@credee.com or call us at 833-DENEFIT. -->
                        <!-- </p> -->
                    </div>
                </div>
                <div class="A4"
                    style="height: 1120px;max-width: 850px;margin: auto;display: table;background: rgba(245, 246, 250, 1.0);">
                    <div class="header"
                        style="text-align:center;height:67px;width: 100%;padding: 0;background-image: linear-gradient(to right, #183813 0%, #b6e0ad 100%);">
                        <img src="assets\images\icons\FM-logo.svg" width="120" style="margin: 20px auto;">
                    </div>
                    <div class="content">
                        <h4 style="padding: 0 15px;margin:0;margin-top:30px">Billing Details</h4>
                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            Billing Street Address : <strong style="padding-bottom: 2px;"
                                *ngIf="patient_Address">{{patient_Address}}</strong>
                            <strong *ngIf="!patient_Address"> ________________________ </strong>
                            <br>
                            City : <strong style="padding-bottom: 2px;" *ngIf="customer_city">{{customer_city}}</strong>
                            <strong *ngIf="!customer_city"> ________________________ </strong>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            State : <strong style="padding-bottom: 2px;"
                                *ngIf="customer_state">{{customer_state}}</strong>
                            <strong *ngIf="!customer_state"> ________________________ </strong>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Zip : <strong style="padding-bottom: 2px;" *ngIf="customer_zip">{{customer_zip}}</strong>
                            <strong *ngIf="!customer_zip"> ________________________ </strong><br>
                            Phone No : <strong style="padding-bottom: 2px;"
                                *ngIf="patient_mobile">{{patient_mobile}}</strong>
                            <strong *ngIf="!patient_mobile"> ________________________ </strong>
                            <br>
                            Email : <strong style="padding-bottom: 2px;"
                                *ngIf="patient_email">{{patient_email}}</strong>
                            <strong *ngIf="!patient_email"> ________________________ </strong>
                        </p>
                        <h4 style="padding: 0 15px;margin:0;"> Card Information</h4>
                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            <!-- - Visa - Mastercard - American Express - Discover<br> -->
                            Cardholder’s Name : ________________________<br>
                            Card Number : ________________________<br>
                            Expiration Date :_______/________ <br>
                            Security Code (CVV) : ________
                        </p>
                        <h4 style="padding: 0 15px;margin:0;"> Bank (ACH) Information</h4>
                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            Name on Account : ________________________<br>
                            Bank Name : ________________________<br>
                            Account Number : ________________________ <br>
                            Routing Number : ________________________
                        </p>
                        <h4 style="padding: 0 15px;margin:0;"> Agreement to Accept Terms and Conditions,
                            Periodic Statements, Notices, Confirmations and Disclosures in an Electronic
                            Format.</h4>
                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            <span> this Agreement, the terms "we", "us" and "our" refer to Finance Mutual LLC
                                ("Finance Mutual"), and the terms "you" and "your" refer to you as a Finance Mutual
                                customer. This Agreement applies to all Finance Mutual accounts established with
                                you (each is an "Account").</span>
                            <span
                                style="display:block;line-height: 2;padding: 7px 0;margin-bottom: 0px;margin-top: 0px">
                                This Electronic Disclosure and Consent contains important information that
                                you are entitled to receive before you consent to transact business with us
                                electronically.</span>
                        </p>
                        <h4 style="padding: 0 15px;margin:0;">Electronic Acceptance of Account Terms.
                        </h4>
                        <p style="line-height: 2;padding: 15px;margin-bottom: 0px;margin-top: 0px">
                            You acknowledge and agree that (a) the Terms and Conditions applicable to your Account(s)
                            (the "Account Terms"), any amendments to the Account Terms, and any one-time authorization
                            for an electronic funds transfer ("EFT") or preauthorization for EFTs to pay amounts due and
                            payable under my Account can be entered into electronically and enforceable in accordance
                            with laws applicable to the recognition of electronic signatures, including without
                            limitation the Electronic Signatures in Global and National Commerce Act (the E-SIGN Act);
                            (b) the electronic signature utilizing the designated method constitutes your signature,
                            acceptance, and agreement the same as if actually signed in writing; and (c) this Agreement
                            shall constitute an "original" document when printed from electronic files and records
                            established and maintained by either party in the normal course of business.</p>
                    </div>
                    <div class="footer">
                        <span>ACH /
                            Card Payment
                            Authorization&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>support&#64;financemutual.com&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>16500
                            Bake Pkwy
                            Irvine, CA 92618</span>
                        <!-- <p style="color:#7f8fa4;font-size: 10px;margin: 0px auto;padding-bottom: 50px">This is system generated statement. If you have any queries, you can reach us anytime at support@credee.com or call us at 833-DENEFIT. -->
                        <!-- </p> -->
                    </div>
                </div>
                <div class="A4"
                    style="height: 1120px;max-width: 850px;margin: auto;display: table;background: rgba(245, 246, 250, 1.0);">
                    <div class="header"
                        style="text-align:center;height:67px;width: 100%;padding: 0;background-image: linear-gradient(to right, #183813 0%, #b6e0ad 100%);">
                        <img src="assets\images\icons\FM-logo.svg" width="120" style="margin: 20px auto;">
                    </div>
                    <div class="content" style="margin-bottom:100px">
                        <h4 style="padding: 0 15px;margin:0;margin-top:30px">Consent to Receive Records in an Electronic
                            Format</h4>
                        <p style="line-height: 2;padding: 0px 15px;margin-bottom: 0px;margin-top: 0px">
                            <span>By clicking your acceptance below, you hereby agree to electronic delivery of notices
                                and records relating to your Account ("Records") in lieu of receiving such Records in
                                paper form through postal delivery and not otherwise required by regulation or policy to
                                be sent exclusively by postal delivery. Records eligible for electronic delivery include
                                periodic statements of your Account. By agreeing to electronic delivery, you will not
                                receive these disclosures by mail.</span>
                            <span
                                style="display:block;line-height: 2;padding: 7px 0;margin-bottom: 0px;margin-top: 0px">
                                We may always, in our sole discretion, provide you with any communication of Records in
                                writing, even if you have chosen to receive it electronically.</span>
                            <span
                                style="display:block;line-height: 2;padding: 7px 0;margin-bottom: 0px;margin-top: 0px">
                                You acknowledge and agree that your consent above is being provided in connection with a
                                transaction affecting interstate commerce that is subject to the E-SIGN Act, and that
                                you and we both intend that the E-SIGN ACT apply to the maximum extent to permit us the
                                ability to conduct business with you by electronic means.</span>
                        </p>

                        <h4 style="padding: 0 15px;margin:0;margin-top:20px">Changes of Terms; Termination</h4>
                        <p style="line-height: 2;padding: 0px 15px;margin-bottom: 0px;margin-top: 0px">
                            We reserve the right, at any time and in our sole discretion, to change the terms and
                            conditions under which we provide Records to you electronically, or to discontinue providing
                            Records to you electronically. We will provide you with advance notice of any such change or
                            termination. Your decision to continue to receive Records electronically will constitute
                            your agreement to any such change.
                        </p>
                        <h4 style="padding: 0 15px;margin:0;margin-top:20px">HIPAA (Health Insurance Portability and
                            Accountability Act) Disclosure</h4>
                        <p style="line-height: 2;padding: 0px 15px;margin-bottom: 0px;margin-top: 0px">
                            I consent that if <strong style="padding-bottom: 2px;"
                                *ngIf="practiceName">{{practiceName}}</strong>
                            <strong *ngIf="!practiceName"> ________________________
                            </strong> is a medical provider of any sort, it may use and disclose Protected Health
                            Information (PHI) or Individually Identifiable Health Information (IIHI) about me to carry
                            out Treatment, Payment, and Healthcare operations (TPO). I, <strong
                                style="padding-bottom: 2px;" *ngIf="patientFirstName"> {{title}} {{patientFirstName}}
                                {{patientlastName}}</strong>
                            <strong *ngIf="!patientFirstName"> ________________________ </strong>, authorize <strong
                                style="padding-bottom: 2px;" *ngIf="practiceName">{{practiceName}}</strong>
                            <strong *ngIf="!practiceName"> ________________________
                            </strong> to release my protected health information to Finance Mutual.
                        </p>
                        <br>
                        <p
                            style="width:50%;min-height: 20px;float:left;padding: 0;margin: 0 15px;font-weight: bold;padding-bottom: 2px;border-bottom: 1px solid #656565;">
                            {{title}} {{patientFirstName}} {{patientlastName}}</p>
                        <div class="clearfix"></div>
                        <p style="padding: 0 15px 0">Name (printed)</p>
                        <p
                            style="width:50%;min-height: 20px;float:left;padding: 0;margin: 0 15px;font-weight: bold;padding-bottom: 2px;border-bottom: 1px solid #656565;">
                            Electronic Customer Signature Received</p>
                        <div class="clearfix"></div>
                        <p style="padding: 0 15px 0">Signature</p>
                        <p
                            style="width:50%;min-height: 20px;float:left;padding: 0;margin: 0 15px;font-weight: bold;padding-bottom: 2px;border-bottom: 1px solid #656565;">
                            {{ date | date: 'MM/dd/yyyy' }}</p>
                        <div class="clearfix"></div>
                        <p style="padding: 0 15px 0">Enrollment Date</p>
                    </div>
                    <div class="footer">
                        <span>ACH /
                            Card Payment
                            Authorization&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            <span>support&#64;financemutual.com&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>16500
                            Bake Pkwy
                            Irvine, CA 92618</span>
                        <!-- <p style="color:#7f8fa4;font-size: 10px;margin: 0px auto;padding-bottom: 50px">This is system generated statement. If you have any queries, you can reach us anytime at support@credee.com or call us at 833-DENEFIT. -->
                        <!-- </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>