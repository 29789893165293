<div class="login-main-wrapper">
    <div class="inner-wrapper d-flex flex-column flex-lg-row">
        <div class="login-right-side flex-fill vh-100 overflow-lg-auto">
            <div class="h-100 w-100 pb-4">
                <div
                    class="header border-bottom drop-shadow pb-4 hw-65 w-100 justify-content-between d-flex align-items-center pt-4 px-4">
                    <div class="fs-18 fw-700 text-justify ps-4"><img src="assets/images/icons/FM-logo-green.svg" alt=""></div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center w-100 overflow-auto hide-scroll-bar"
                    style="height: calc(100% - 125px);top: 40px">
                    <div class="w-100 right-content p-1" style="max-width: 600px;">
                        <div class="d-flex justify-content-center align-items-center w-100 flex-column p-3 pb-4" style="padding: 50px;">
                            <img src="assets/images/icons/greenTick.svg" alt="" class="mb-4">
                            <p class="fs-24 fw-800 mb-0 text-center mx-auto" style="color: rgba(19, 25, 33, 1);max-width: 530px;">Thank you for confirming your attendance for the scheduled training session.</p>
                            <p class="fw-600 fs-16 my-4 text-center py-2 w-100 mx-auto radius-8" style="color: rgba(65, 65, 65, 1);max-width:386px;">We have successfully received your confirmation and look forward to having you join us.
                            </p>
                          </div>
                    </div>
                </div>
                <div class="footer flex-row d-flex border-top justify-content-between pb-2 pt-4 px-7 right-padding">
                    <p>Copyright © 2024 Finance Mutual. All Rights Reserved. </p>
                    <p class="fs-12 fw-400" style="color: rgba(121, 127, 135, 1);">Powered by <img class="hw-26" src="assets/images/icons/logo-circle-26.png" alt=""> <span class="fs-12 fw-600" style="color: rgba(18, 44, 11, 1);"> Finance Mutual</span></p>
                </div>
            </div>
        </div>
    </div>
</div>