<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="" >
      <app-left-layout></app-left-layout>
    </div>
    <div class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center overflow-lg-auto vh-100"
      >
      <div class="right-content w-100  p-3 p-lg-0">
        <h1 class="fs-36 fw-600 m-0 mb-3" >
          {{'CREATE NEW' | titlecase}}<span style="color:var(--d-blue)"> {{'PASSWORD!' | titlecase}}</span>
        </h1>
        <form class="w-100 " [formGroup]="newPasswordForm" (submit)="submitForm()">
          <div class=" position-relative">
            <label for="text" class=" fs-14 w-100">
              New Password
            </label>
            <div class="position-relative">
              <input [type]="hideNewPassword == true ? 'password' : 'text'" class="input_field w-100" name="password"
                formControlName="newPassword" autocomplete="off" required maxlength="75"
                placeholder="Enter New Password" />
              <img [src]="hideNewPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                alt="" class="position-absolute eye-icon cursor" (click)="hideNewPassword = !hideNewPassword">
            </div>
            <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
              *ngIf="newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].invalid && !newPasswordForm.controls['newPassword'].dirty">
              Please valid enter password.
            </p>
          </div>
          <div class="position-relative">
            <label for="text" class="fs-14 w-100">
              Confirm Password
            </label>
            <div class="position-relative">
              <input [type]="hideConfirmPassword == true ? 'password' : 'text'" class="input_field w-100"
                name="password" formControlName="confirmPassword" autocomplete="off" required maxlength="75"
                placeholder="Confirm Your Password" />
              <img
                [src]="hideConfirmPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                alt="" class="position-absolute eye-icon cursor" (click)="hideConfirmPassword = !hideConfirmPassword">
            </div>
          </div>
          <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
            *ngIf="(newPasswordForm.controls['confirmPassword'].touched && newPasswordForm.controls['confirmPassword'].invalid && !newPasswordForm.controls['confirmPassword'].dirty) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'] && newPasswordForm.controls['confirmPassword'].touched )">
            {{ newPasswordForm.errors && newPasswordForm.errors['noMatch'] &&
            newPasswordForm.value['confirmPassword'].length > 0 ? "Passwords did not match." : 'Please enter a
            valid password.'}}
          </p>
          <span class="w-100 d-flex justify-content-end">
            <a routerLink="/login" class="fw-600 cursor fs-14"> Back to Login </a>
          </span>
        <button button-primary-auth type="submit" class="w-100 border-0 mt-3 btn btn-secondary fs-16 fw-600 "
          [disabled]="(newPasswordForm.invalid) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'])"
          >
          Change  Password
        </button>
        </form>
      </div>
    </div>
  </div>
</div>