import { Injectable } from "@angular/core";
import { md5 } from "./md5.service";
import { ToasterService } from "../shared/toaster/toaster.service";
import { localStorageService } from "./localstorage.service";
import { BehaviorSubject } from "rxjs";
import { utils } from 'xlsx';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
declare let Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public country_data$: BehaviorSubject<any> = new BehaviorSubject({});

  // this element used to create stripe element from stripe key.
  public stripeElement: any;
  cardBrands = ['Mastercard', 'Discover', 'Jcb', 'Visa', 'American Express', 'Unionpay', 'Diners','Amex'];
  public dateFormating: any = {
    US: {
      specialCharacters: ['/'],
      PlaceHolder: 'MM/DD/YYYY',
      fieldOrder: [{
        key: 'month',
        placeholder: 'MM'
      },
      {
        key: 'day',
        placeholder: 'DD'
      },
      {
        key: 'year',
        placeholder: 'YYYY'
      }]
    },
    CA: {
      formatting: '0000-M0-d0',
      placeHolder: 'YYYY-MM-DD',
      specialCharacters: ['-']
    },
    GB: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    AU: {
      formatting: 'd0-M0-0000',
      placeHolder: 'DD-MM-YYYY',
      specialCharacters: ['-']
    },
    NZ: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    FR: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    DE: {
      formatting: 'd0.M0.0000',
      placeHolder: 'DD.MM.YYYY',
      specialCharacters: ['.']
    },
    IT: {
      formatting: 'd0/M0/0000',
      placeHolder: 'DD/MM/YYYY',
      specialCharacters: ['/']
    },
    JP: {
      formatting: '0000/M0/d0',
      placeHolder: 'YYYY/MM/DD',
      specialCharacters: ['/']
    },
    PL: {
      formatting: 'd0.M0.0000',
      placeHolder: 'DD.MM.YYYY',
      specialCharacters: ['.']
    },
    ES: {
      formatting: 'd0-M0-0000',
      placeHolder: 'DD-MM-YYYY',
      specialCharacters: ['-']
    },
  };

  public contractType: any = {
    6: 'Protected Ez Plan',
    2: 'Protected No Fee'
  }

  public contractTypeKeys: { [key: number]: string } = {
    3: 'ihf',
    2: 'nff',
  };

  public downloadType = {
    DOWNLOAD: 0,
    PRINT: 2
  }

  public defferedMonths = 12;

  public contractTypes = {
    noFee: 2,
    ez_with_deffered: 6
  };

  

  public stripeKey$: BehaviorSubject<any> = new BehaviorSubject('');
  public language_data: languageData[] = [];
  public language_data$: BehaviorSubject<languageData[]> = new BehaviorSubject<languageData[]>([]);

  public country_currency_info$: BehaviorSubject<country_currency_info> = new BehaviorSubject<country_currency_info>({});
  public search_emitter = new BehaviorSubject<string>('');

  public bank_details$: BehaviorSubject<bank_details> = new BehaviorSubject<bank_details>({
    bank_name: '',
    routing_number: '',
    account_number: '',
    account_holder_name: '',
    account_holder_type: '',
  });

  public auth_docs$: BehaviorSubject<any> = new BehaviorSubject({});
  public identity_status$: BehaviorSubject<any> = new BehaviorSubject({});
  public doctorSettings$: BehaviorSubject<any> = new BehaviorSubject({});
  public authenticateApiResponse$: BehaviorSubject<any> = new BehaviorSubject({});
  public cancel_contract_fee$: BehaviorSubject<any> = new BehaviorSubject({});
  public  today = new Date();
  public sideMenus$: BehaviorSubject<menu[]> = new BehaviorSubject<menu[]>([]);
  public doctorProfile$: BehaviorSubject<userProfile> = new BehaviorSubject({
    doctor_email: '',
    doctor_name: ''
  });

  public paymentSourceType = {
    CARD: 3,
    BANK: 1
  }

  public paymentMode = {
    BUSINESS: 2,
    CUSTOMER: 1
  }

  key_sort_by: any;
  signaturePadBase64 = '';
  doctorProfileImage = ''
  identityStatus = {
    verified: 'identity_verified'  ,
    business_verified:'business_verified',
    unverified: 'unverified',
    processing:'verification_processing',
    pending:'verification_pending'

  }
  public dynamicColorBgStyle = [
    { bg: '#E1FDF3', color: '#0F6648' },
    { bg: '#FFC737', color: '#000' },
    { bg: '#FFEFF0', color: '#76012C' },
    { bg: '#E1FDF3', color: '#18B557' },
    { bg: '#f0f0f0', color: '#0F6648' },
    { bg: '#eaf2ff', color: '#0F6648' },
    { bg: '#f8e7dc', color: '#0F6648' },
    { bg: '#f0ffe0', color: '#0F6648' },
    { bg: '#fff0f5', color: '#0F6648' },
  ];

  randomIndex = Math.floor(Math.random() * (this.dynamicColorBgStyle.length - 1));

  socketIds = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    DOCTOR_CONNECTED: 'DOCTOR_CONNECTED',
    DOCTOR_LOGOUT: 'DOCTOR_LOGOUT',
    CONTINUE_FINANCE_ON_BUSINESS: 'CONTINUE_FINANCE_ON_BUSINESS'
  };

  risk_type = {
    HIGH_RISK_FINANCING: 'HIGH_RISK_FINANCING',
    SECURE_FINANCING: 'SECURE_FINANCING',
    NOT_ELIGIBLE: 'NOT_ELIGIBLE'
  }

  
    countryWiseRegex: any = {
      US: {
        regex: /(\d{3})(\d{3})(\d{4})/g,
        group: '$1-$2-$3'
      },
      CA: {
        regex: /(\d{3})(\d{3})(\d{4})/g,
        group: '$1-$2-$3'
      },
      GB: {
        regex: /(\d{4})(\d{5})/g,
        group: '$1 $2'
      },
      AU: {
        regex: /(\d{1})(\d{4})(\d{4})/g,
        group: '$1 $2 $3'
      },
      NZ: {
        regex: /(\d{1})(\d{4})(\d{4})/g,
        group: '$1 $2 $3'
      },
      DE: {
        regex: /(\d{4})(\d{7})/g,
        group: '$1 $2'
      },
      IT: {
        regex: /(\d{3})(\d{7})/g,
        group: '$1 $2'
      },
      FR: {
        regex: /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/g,
        group: '$1 $2 $3 $4 $5'
      },
      ES: {
        regex: /(\d{3})(\d{6})/g,
        group: '$1 $2'
      },
      PL: {
        regex: /(\d{3})(\d{3})(\d{3})/g,
        group: '$1 $2 $3'
      },
      JP: {
        regex: /(\d{2})(\d{4})(\d{4})/g,
        group: '$1 $2 $3'
      },
    };

  constructor(private _toaster: ToasterService, private _local: localStorageService) {
    this.stripeKey$.subscribe(stripeKey => {
      if (stripeKey) {
        this.stripeElement = Stripe(stripeKey);
      }
    })
  }

  customPopups(message: string, flag: Number) {
    if (flag == 1) {
      this._toaster.showToaster('Oops!', message, 'error');
    } else if (flag == 2) {
      this._toaster.showToaster('Warning', message, 'warning');
    } else {
      this._toaster.showToaster('Success', message, 'success');
    }
  }

  hideAllToasters() {
    this._toaster.hideAllToasters();
  }

  crypto(tokenFromUI: string) {
    return md5(tokenFromUI);
  }

  updateMasking(format: '') {
    return format.replace(/#/g, '0');
  }

  guid() {
    const a = new Date();
    const nav = window.navigator;
    const screen = window.screen;
    let guid = nav.mimeTypes.length.toString();
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    guid += a.getTime();
    return guid;
  }

  device_id() {
    if (!this._local.customGetItem('user')) {
      const guidLog = this.guid();
      this._local.customSetItem('user', guidLog);
    }
  }

  getCurrentTimezone() {
    let time = new Date().getTimezoneOffset();
    let hours = Math.abs(Math.trunc(time / 60));
    let minutes = ("0" + time % 60).slice(-2);
    let user_timezone = '';
    if (time > 0) {
      user_timezone = "-" + hours + minutes;
    } else {
      user_timezone = "+" + hours + minutes;
    }
    return user_timezone;
  }

  async base64ToBlob(base64String: string) {
    const dataURI = base64String;
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {
      type: 'image/jpeg'
    });
    return blob;
  }

  sortByFun(data: Array<[]>, key_sort_by: string): [][] {
    this.key_sort_by = key_sort_by;
    let sortedData: any;
    if (data) {
      sortedData = data.sort((a: any, b: any) => a[key_sort_by] - b[key_sort_by]);
    }
    return sortedData;
  }

  compare(a: any, b: any) {
    if (a && a[this.key_sort_by] < b && b[this.key_sort_by])
      return -1;
    if (a && a[this.key_sort_by] > b && b[this.key_sort_by])
      return 1;
    return 0;
  }

  updatePanelLanguage(lan_code: string) {
    const value = `/en/${lan_code}`;
    this._local.customSetItem('lan_code', lan_code);
    if (this._local.getCookie('googtrans') && this._local.getCookie('googtrans') == value) {
      return;
    }
    const anchorElement = document.querySelector(`a[data-gt-lang="${lan_code}`);
    setTimeout(() => {
      if (anchorElement instanceof HTMLElement) {
        anchorElement.click();
      }
    }, 1000);
  }

  // Check if the date is valid
  isValidDate(dobData: dobData): boolean {
    if (!dobData) {
      return false;
    }
    const monthNum = parseInt(dobData.month, 10);
    const dayNum = parseInt(dobData.day, 10);
    const yearNum = parseInt(dobData.year, 10);

    // Create a date object
    const date = new Date(yearNum, monthNum - 1, dayNum);

    // Check if the date object matches the input date
    const dates = date.getFullYear() === yearNum && date.getMonth() === monthNum - 1 && date.getDate() === dayNum;
    return dates;
  }

  restrictSpaces(value: string) {
    return value.replace(/\s+/g, ''); // Replace all spaces
  }

  isWeekend(date: Date) {
    const day = date.getDay();
    return (day === 6 || day === 0); // 6 is Saturday, 0 is Sunday
  }

  ssncustomValidator(value: string): boolean {
    // Your validation logic goes here
    const invalid_ssn = ['002281852', '042103580', '062360749', '078051120', '095073645', '128036045', '135016629', '141186941', '165167999', '165187999', '165207999', '212099999', '165227999', '306302348', '165247999', '189092294', '308125070', '212097694', '549241889', '468288779', '123456789', '012345678'];

    if (value && (value.trim().length != 9 || value.slice(5, 9) == "0000" || value.slice(3, 5) == "00" || /^(.)\1+$/.test(value) || /[a-zA-Z]/.test(value) || value.slice(0, 3) == "000" || value.slice(0, 3) == "666" || invalid_ssn.indexOf(value) >= 0 || value == "9876543210" || parseInt(value) >= 987654320 && parseInt(value) <= 987654329)){
      return true;
    }
    return false;
  }

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    ext: string
  ): void {
    try {
      console.log('Exporting:', excelFileName, 'Extension:', ext);
      console.log('JSON Data:', json);
  
      if (!json || !Array.isArray(json) || json.length === 0) {
        throw new Error('Invalid or empty JSON data.');
      }
  
      // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      //   skipHeader: true,
      // });

      const data = json[0].list; // Ensure this is an array of objects with matching keys

      // Generate worksheet with headers and data
      const worksheet = utils.json_to_sheet(data, {skipHeader:true });
      
      // const worksheet2 =  utils.json_to_sheet(json[0].list1.map((item :any)=> item) ) ;
      // console.log(worksheet2,"worksheet2");
      
      const workbook: XLSX.WorkBook = {
        Sheets: { Report:  worksheet },
        SheetNames: ['Report'],
      };
  
      const bookType = ext === '.xlsx' ? 'xlsx' : ext === '.csv' ? 'csv' : null;
      if (!bookType) {
        throw new Error('Unsupported file extension.');
      }
  
      const excelBuffer: any = XLSX.write(workbook, { bookType, type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName, ext);
    } catch (error :any) {
      console.error('Error exporting file:', error);
    }
  }

  private saveAsExcelFile(
    buffer: any,
    fileName: string,
    extension: string
  ): void {
    console.log(fileName);
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName + '_export' + extension);
  }

  public exportAsPdfFile(json: any[], pdfFileName: string): void {
    try {
  
      if (!json || !Array.isArray(json) || json.length === 0) {
        throw new Error('Invalid or empty JSON data.');
      }
  
      const data = json[0].list; 
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw new Error('Invalid or empty data in json[0].list.');
      }
  
      const doc = new jsPDF();
  
      // Extract column headers from the first object keys
      const headers = Object.keys(data[0]).map(key => ({ header: key, dataKey: key }));
  
      // Use autoTable to generate table from the JSON data
      (doc as any).autoTable({
        margin: { top: 5, bottom: 5, left: 5, right: 5 },
        // head: [headers.map(h => h.header)],
        body: data.map(row => headers.map(h => row[h.dataKey])),
      });
  
      doc.save(`${pdfFileName}.pdf`);
    } catch (error: any) {
      console.error('Error exporting PDF file:', error);
    }
  }  
  get_interest_to_show_value(term: any, contract_type: any, number_of_payments: any) {
    return contract_type == 3 ? (term || number_of_payments) > 5 ? 'C' : 'F' : (term || number_of_payments) > 12 ? 'C' : 'F';
  }
}

export interface dobMask {
  specialCharacters: string[],
  fieldOrder: [
    {
      key: string,
      placeholder: string
    },
    {
      key: string,
      placeholder: string
    },
    {
      key: string,
      placeholder: string
    },
  ]
}

export interface languageData {
  created: string,
  is_active: number,
  lan_code: string,
  lan_name: string,
  original_lan_name: string
}

export interface paymentSourceType {
  brand: string,
  exp_month: number,
  exp_year: number,
  last4: string,
  payment_source_type: number,
  source_id: number
}

export interface languageType {
  lan_code: string,
  lan_name: string,
  original_lan_name: string
}

export interface dobData {
  month: string,
  day: string,
  year: string
}

export interface userProfile {
  doctor_name: string,
  doctor_email: string,
  business_verification_state?: string,
  google_id?: string,
}

export interface country_currency_info {
  country?: country,
  currency?: currency[],
  mobile_regex?: string,
  doctor_id?: number,
  identity_document_required?: boolean
}

export interface country {
  country_code: string,
  country_id: number,
  country_name: string,
  country_phone_format: string,
  identity_document_max_length: number,
  identity_document_min_length: number,
  enable_bank: number,
  identity_label: string
  mobile_code: string,
  show_instant_payment_card: number
}

export interface currency {
  currency_id: number,
  display_logo: string,
  currency_code: string,
  currency_name: string,
  exchange_rate: number,
  bank_support_type: string[]
}

export interface bank_details {
  bank_name: string,
  routing_number: string,
  account_number: string,
  account_holder_name: string,
  account_holder_type: string,
}

export interface menu {
  menu_id: number,
  menu_name: string,
  menu_enum: string,
  path: string,
  all_restricted_items: string,
  icon: string,
  in_order: number,
  status: string,
  only_path: string,
  parent_id: string,
  created_by: string,
  modified_by: number,
  created_at: string,
  modified_at: string,
  show_to_business: number,
  role_id: number,
  restricted_items: string,
  text: string,
  link: string,
  submenu: [menu]
}


export const getDateSuffix = (date: any) => {
  let suffix = 'st';
  if (date) {
      suffix = (date == 11 || date == 12 || date == 13) ? 'th' : String(date).slice(-1) == '1' ? "st" : String(date).slice(-1) == '2' ? "nd" : String(date).slice(-1) == '3' ? "rd" : 'th';
  }
  return suffix;
}

export const getHtmlWithInputValues = (element_id: string) => {
  const originalElement = document.getElementById(element_id);

  if (!originalElement) {
    console.error("Element with ID 'A4' not found.");
    return '';
  }

  // Clone the element to avoid modifying the original DOM
  const clonedElement = originalElement.cloneNode(true) as HTMLElement;

  // Select all input, textarea, and select elements
  clonedElement.querySelectorAll('input, textarea, select').forEach((el) => {
    if (el instanceof HTMLInputElement) {
      // Handle checkboxes & radio buttons separately
      if (el.type === 'checkbox' || el.type === 'radio') {
        if (el.checked) {
          el.setAttribute('checked', 'checked');
        } else {
          el.removeAttribute('checked');
        }
      } else {
        el.setAttribute('value', el.value);
      }
    } else if (el instanceof HTMLTextAreaElement) {
      // Set textarea content
      el.innerHTML = el.value;
    } else if (el instanceof HTMLSelectElement) {
      // Set selected option
      const selectedOption = el.options[el.selectedIndex];
      if (selectedOption) {
        selectedOption.setAttribute('selected', 'selected');
      }
    }
  });

  // Get the updated HTML with input values
  return clonedElement.innerHTML;
}

export const passwordValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    if (!password) {
      return null;
    }

    const minLength = /.{6,12}/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    const valid = minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
    return !valid ? {
      passwordStrength: {
        requirements: `Password must be 6-12 characters long, and include at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9), and one special character (!@#$%^&*).`
      }
    } : null;
  };
}

