import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
// import { IntercomModule } from 'ng-intercom';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { CaptchaService } from './services/captcha.service';
import { InterceptorService } from './services/interceptor.service';
import { MainService } from './services/main.service';
import { SharedService } from './services/shared.service';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = { url: `${environment.socketBaseUrl}`, options: { path: "/api/socket.io/" } };

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    // SocketIoModule.forRoot(config),
    SharedModule.forRoot(),
    // IntercomModule,
    RecaptchaV3Module
  ],
  providers: [
    // { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.captcha_site_key,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    SharedService,
    MainService,
    CaptchaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
